import { UserSettingsService } from './../../services/user-settings.service';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss'],
})
export class UpdateDetailsComponent implements OnInit {
  isLoading = false;
  error: string = null;
  updated = false;
  userData = {
    email: null,
    firstName: null,
    lastName: null,
  };
  userSettings;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userData.firstName = user.first_name;
        this.userData.lastName = user.last_name;
        this.userData.email = user.email;
      }
    });
    this.userSettingsService.fetchAll().subscribe((responseData) => {
      this.userSettings = responseData;
    });
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const firstName = form.value.firstName;
    const lastName = form.value.lastName;
    let authObs: Observable<any>;

    this.isLoading = true;

    if (form.value.exerciseLevel) {
      this.userSettingsService
        .saveSetting('exerciseLevel', form.value.exerciseLevel)
        .subscribe((responseData) => {
          this.userSettings['exerciseLevel'] = '' + form.value.exerciseLevel;
        });
    }
    authObs = this.authService.updateDetails(email, firstName, lastName);

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        this.updated = true;
        this.router.navigate(['/dashboard']);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Details updated',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );
  }
}
