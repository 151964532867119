import { ActivatedRoute } from '@angular/router';
import { ResourcesService } from './../resources.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-resource-index',
  templateUrl: './resource-index.component.html',
  styleUrls: ['./resource-index.component.scss'],
})
export class ResourceIndexComponent implements OnInit {
  pages;
  resourceId;
  resource;
  isFetching = false;
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.resourceId = params.id;
      this.isFetching = true;
      this.resourcesService
        .fetchResource(this.resourceId)
        .subscribe((responseData) => {
          this.isFetching = false;
          this.pages = responseData.pages;
          this.resource = responseData.resource;
          // set custom HTML title
          this.titleService.setTitle(environment.title + ' | Resources | ' + this.resource.title);
        });
    });
  }
}
