<div class="accordion pb-3" id="resourcesSubnav">
  
  <div class="card">
    <div
      class="card-header"
      id="linkOverview"
    >
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/techniques']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkOverview.isActive"
      >
        Overview
      </button>
    </div>
  </div>
  <div class="card" *ngFor="let page of pages">
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link collapsed"
          type="button"
          [routerLink]="['/techniques', page.content_page_id]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onToggleMenu()"
        >
          {{ page.title }}
        </button>
      </h2>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link"
          type="button"
          [routerLink]="['/techniques', 'action-plan']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          #linkAP="routerLinkActive"
          [class.collapsed]="!linkAP.isActive"
        >
          Action Plan
        </button>
      </h2>
    </div>
  </div>
</div>
