import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { take, exhaustMap, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
           headers: new HttpHeaders().set('Authorization', 'Bearer ' + user.token),
        });
        return next.handle(modifiedReq).pipe( tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
         /* if (err.status !== 401) {
           return;
          }
          this.router.navigate(['login']);*/
        }
      }));
      })
    );
  }
}
