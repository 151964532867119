import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { WithCredentialsInterceptorService } from './shared/with-credentials-interceptor.service';
import {AuthRefreshInterceptorService} from './auth/auth-refresh-interceptor.service';
import { AuthInterceptorSignatureService } from './auth/auth-signature-interceptor.service';
export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthRefreshInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorSignatureService, multi: true },
];
