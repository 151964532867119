import { UserSettingsService } from './../services/user-settings.service';
import { forkJoin } from 'rxjs';
import { ResourcesService } from './../resources/resources.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  isFetching = false;
  resource;

  constructor(
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    forkJoin({
      resource: this.resourcesService.fetchResource(1),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;
        this.resource = responseData.resource;
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.userSettingsService
      .saveSetting('watchIntro', 1)
      .subscribe((responeData) => {});
  }
}
