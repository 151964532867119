import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap, take, exhaustMap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

import { User } from './user.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../environments/environment';

export interface AuthResponseData {
  kind: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
    app_token: string;
    api_token: {access_token, expires_in};
  };
  id: string;
  /*email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;*/
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;
  public redirect: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {}

  login(email: string, password: string, rememberMe) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('email', email)
      .set('password', password)
      .set('returnSecureToken', 'true');

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/login', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

          // are we remebering it?
          if (rememberMe) {
            // this.autoLogout(expiresIn * 1000);
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            const exp = new Date(now);

            // store in cookies
            this.cookieService.set('appToken', resData.user.app_token, exp, '/');
            this.cookieService.set('appEmail', resData.user.email, exp, '/');
          }
        })
      );
  }

  forgottenPassword(email: string) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('email', email);

    return this.http
      .post<any>(environment.apiUrl + '/forgotten-password', payload)
      .pipe(
        catchError(this.handleError)
      );
  }

  resetPassword(id: number, hash: string, password: string, passwordConfirm: string) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('password', password)
      .set('password_confirm', passwordConfirm);

    return this.http
      .post<any>(environment.apiUrl + '/reset-password/' + id + '/' + hash , payload)
      .pipe(
        catchError(this.handleError)
      );
  }

  changePassword(currentPassword: string, password: string, passwordConfirm: string) {
    const payload = new HttpParams()
      .set('current_password', currentPassword)
      .set('password', password)
      .set('password_confirm', passwordConfirm);

    return this.http
      .post<any>(environment.apiUrl + '/auth/change-password' , payload)
      .pipe(
        catchError(this.handleError)
      );
  }
  register(code: string, email: string, password: string, passwordConfirm: string, firstName: string, lastName: string, ) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('code', code)
      .set('email', email)
      .set('password', password)
      .set('password_confirm', passwordConfirm)
      .set('first_name', firstName)
      .set('last_name', lastName)
      .set('returnSecureToken', 'true');

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/register', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );
        })
      );
  }

  updateDetails(email: string, firstName: string, lastName: string, ) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('email', email)
      .set('first_name', firstName)
      .set('last_name', lastName);
    return this.http
      .post<any>(environment.apiUrl + '/auth/update-details', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

        })
      );
  }

  autoLogin() {
    const appEmail = this.cookieService.get('appEmail');
    const appToken = this.cookieService.get('appToken');

    if (!appToken || !appEmail) {
      //return;
    }

    // use params as posting to form
    const payload = new HttpParams()
      .set('email', appEmail)
      .set('token', appToken)
      .set('returnSecureToken', 'true');

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/restore', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );
        })
      );
  }

  getAccessToken() {
    this.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (user) {
          return user.token;
        }
      }));
      return null;
  }


  refreshAccessToken() {
    const appToken = this.cookieService.get('appToken');
    // use params as posting to form
    const payload = new HttpParams()
      .set('token', appToken)
      .set('returnSecureToken', 'true');

    return this.http
      .post<any>(environment.apiUrl + '/refresh', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );
        })
      );

  }

  logout(): any {

    this.user.next(null);
    this.cookieService.delete('appToken');
    this.cookieService.delete('appEmail');
    this.http
      .get<any>(environment.apiUrl + '/logout', {
        responseType: 'json',
      })
      .subscribe(
        (response) => {
          this.router.navigate(['/login']);
          return false;
        },
        (error) => {
          return error;
        }
      );
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(
    email: string,
    userId: string,
    token: string,
    userData: { first_name; last_name },
    api_token: string,
    expiresIn: number,
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(
      email,
      userId,
      userData.first_name,
      userData.last_name,
      token,
      api_token,
      expirationDate
    );
    this.user.next(user);
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes.statusText) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email exists already';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'This email does not exist.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'This password is not correct.';
        break;
      case 'INVALID_CREDENTIALS':
        errorMessage = 'The email or password is incorrect.';
        break;
      case 'INVALID_REGISTRATION_DATA':
        errorMessage = errorRes.error;
        break;
      case 'INVALID_DATA':
        errorMessage = errorRes.error;
        break;
      case 'ACTION_NOT_ALLOWED':
          errorMessage = errorRes.error;
          break;
    }
    return throwError(errorMessage);
  }
}
