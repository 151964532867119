<app-subnav [title]="'All Workouts'" [section]="'exercise'"></app-subnav>
<div class="container mobile-footer-padding content-container">
  <div class="row">
    <div class="col col-xs">

      <button class="btn btn-primary btn-block" [routerLink]="['/exercise', 'add']"><span class="icon-wrap"><fa-icon [icon]="['far', 'calendar-edit']"></fa-icon></span> Add Entry</button>
    </div>
    <!--div class="col col-xs">
      <button class="btn btn-primary btn-block" [routerLink]="['/exercise', 'create-workout']"><span class="icon-wrap"><fa-icon [icon]="['fas', 'walking']"></fa-icon></span> Start New Workout</button>
    </div-->
  </div>
  <div class="row mt-4" *ngIf="workouts">
    <div class="col">

        <h4 class="content-title">
            {{workouts.length}} Workout<span *ngIf="workouts.length!=1">s</span>
          </h4>
          <table class="record-table">
            <tbody>
                <tr *ngFor="let workout of workouts" [routerLink]="['/exercise', 'workouts', 'view', workout.workout_id]">
                    <td class="td-date">
                        <span class="num">{{workout.workout_date | date : "dd"}}</span>
                        <span class="month">{{workout.workout_date | date : "MMM"}}</span>
                      </td>
                      <td>
                        <span class="r-title">Bed</span>
                        <span class="r-total">
                          <span *ngIf="workout.bed_reps">
                            <span *ngIf="workout.bed_reps === '0'">N/A</span>
                            <span *ngIf="workout.bed_reps !== '0'">{{workout.bed_reps}} total rep<span *ngIf="workout.bed_reps!=1">s</span></span>
                          </span>
                          <span *ngIf="!workout.bed_reps">0 total reps</span>
                        </span>
                    </td>
                      <td>
                          <span class="r-title">Sitting</span>
                          <span class="r-total">


                            <span *ngIf="workout.sitting_reps === '0'">N/A</span>
                            <span *ngIf="workout.sitting_reps !== '0'">{{workout.sitting_reps}} total rep<span *ngIf="workout.sitting_reps!=1">s</span></span>
                          </span>
                      </td>
                      <td>
                          <span class="r-title">Standing</span>

                          <span class="r-total">
                          <span *ngIf="workout.standing_reps === '0'">N/A</span>
                          <span *ngIf="workout.standing_reps !== '0'">{{workout.standing_reps}} total rep<span *ngIf="workout.standing_reps!=1">s</span></span>
                          </span>
                      </td>
                      <td>
                          <div class="circle bg-primary">
                            {{workout.rep_score}}
                          </div>
                      </td>
                      <td><i class="far fa-ellipsis-v"></i></td>
                </tr>
            </tbody>
        </table>

    </div>
  </div>
</div>
