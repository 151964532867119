import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { Goal } from './goal.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  error = new Subject<string>();

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  fetchGoals(status?: number) {
    let searchParams = new HttpParams();
    if (status) {
      searchParams = searchParams.append('status', status.toString());
    }
    return this.http
      .get<any>(environment.apiUrl + '/goals', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const goalsArray: Goal[] = [];
          responseData['_embedded'].goals.forEach((goal) => {
            goalsArray.push(goal);
          });
          return goalsArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/goals/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const goal = new Goal(
            responseData.goal_id,
            responseData.userId,
            responseData.goal,
            responseData.how,
            responseData.support,
            responseData.review,
            responseData.status,
            responseData.created,
            responseData.modified
          );
          return goal;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createGoal(goal, how, support, review) {
    const goalData: Goal = { goal, how, support, review: this.datePipe.transform(review, 'yyyy-MM-dd'), status: 1 };
    return this.http
      .post<{ name: string }>(
       environment.apiUrl + '/goals',
        goalData,
        {
          observe: 'response',
        }
      );
  }

  updateGoal(
    goal: Goal
  ) {
    const goalData = {
      goal: goal.goal,
      how: goal.how,
      support : goal.support,
      review: this.datePipe.transform(goal.review, 'yyyy-MM-dd'),
      status: goal.status,
    };
    return this.http.patch<{ name: string }>(
     environment.apiUrl + '/goals/' + goal.goal_id,
     goalData,
      {
        observe: 'response',
      }
    );
  }


  delete(id) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/goals/' + id
    );
  }
}
