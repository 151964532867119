<div class="container mobile-footer-padding content-container">


<div class="row mt-0" *ngIf="repData">
    <div class="col-md-12">

        <h4 class="content-title">
            workout Complete
          </h4>


          <div class="card card-widget mt-4" *ngIf="repData | getTypeReps : 'bed' : exercises">
            <div class="card-body">
                <div class="row mt-0">
                    <div class="col text-left"><h5 class="card-title card-title-u">Bed based <span class="float-right">{{repData | getTypeReps : 'bed' : exercises}} Reps</span></h5></div>
                </div>
                <div class="row text-left mt-0">
                    <div class="col small">
                       <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'bed'})">
                        <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                      </span>

                    </div>
                </div>

            </div>
          </div>

          <div class="card card-widget mt-4" *ngIf="repData | getTypeReps : 'sit' : exercises ">
            <div class="card-body">
                <div class="row mt-0">
                    <div class="col text-left"><h5 class="card-title card-title-u">Sitting <span class="float-right">{{repData | getTypeReps : 'sit' : exercises}} Reps</span></h5></div>
                </div>
                <div class="row text-left mt-0">
                    <div class="col small">
                       <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'sit'})">
                        <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                      </span>

                    </div>
                </div>

            </div>
          </div>

          <div class="card card-widget mt-4" *ngIf="repData | getTypeReps : 'stand' : exercises">
            <div class="card-body">
                <div class="row mt-0">
                    <div class="col text-left"><h5 class="card-title card-title-u">Standing <span class="float-right">{{repData | getTypeReps : 'stand' : exercises}} Reps</span></h5></div>
                </div>
                <div class="row text-left mt-0">
                    <div class="col small">
                      <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'stand'})">
                       <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                     </span>
                    </div>
                </div>

            </div>
          </div>

          <div class="jumbotron text-center mt-4">
            <h5>TOTAL REPS SCORE</h5>
            <span class="circle bg-dark">{{repData | getTypeReps : 'total' : exercises}}</span>
        </div>


        <button class="btn btn-white btn-block" (click)="onDelete()">
          <span class="icon-wrap text-danger"><fa-icon
              [icon]="['far', 'trash-alt']"
              [fixedWidth]="true"
            ></fa-icon>
      
          Delete Workout
          
            
          </span>
        </button>
    </div>
  </div>
  </div>