import { UserSettingsService } from './../services/user-settings.service';
import { Component, OnInit } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss'],
})
export class ActionPlanComponent implements OnInit {
  isFetching = false;
  statements = [
    'I am going to use the lean forward recovery position',
    'I am going to use the breathing recovery technique; fan, forward lean, focus',
    'I am going to use my fan Focus and  gently breathing out',
    'I am going to self-reassure my myself by saying; I can do this – I am doing it now I don’t need to be frightened – I am OK',
    'I am going to use my fan Focus and use purse lipped breathing',
    'I am not going to panic',
    'I am going to focus on breathing control',
    'I am going to use the calming hand',
    'I will read  the breathlessness poem',
  ];
  step = null;
  selectedStatements = [];
  currentPlan = null;
  textAreasList: any = ['text_area1'];

  private currentDraggableEvent: DragEvent;
  private currentDragEffectMsg: string;
  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    this.userSettingsService.fetchAll().subscribe((responseData) => {
      if (responseData.currentPlan) {
        this.currentPlan = JSON.parse(responseData.currentPlan);
        this.selectedStatements = this.currentPlan;

        this.currentPlan.forEach((plan) => {
          if (this.statements.indexOf(plan) === -1) {
            this.statements.push(plan);
          }
        });

        this.step = 0;
      } else {
        this.step = 1;
      }
    });

    polyfill({
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    });

    window.addEventListener('touchmove', () => {}, { passive: false });
  }

  onSelectStatement(statement): void {
    if (this.selectedStatements.indexOf(statement) === -1) {
      this.selectedStatements.push(statement);
    } else {
      const index = this.selectedStatements.indexOf(statement);
      if (index > -1) {
        this.selectedStatements.splice(index, 1);
      }
    }
  }

  onDragStart(event: DragEvent) {
    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent) {
    this.currentDraggableEvent = event;
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  onNext() {
    if (this.step === 2) {
      this.textAreasList.forEach((textarea) => {
        let elem: HTMLInputElement;
        elem = document.querySelector('#' + textarea);
        if (elem.value) {
          this.selectedStatements.push(elem.value);
        }
      });
    }
    this.step++;
  }
  onEdit() {
    this.step = 1;
  }
  onSave() {
    this.userSettingsService
      .saveSetting('currentPlan', JSON.stringify(this.selectedStatements))
      .subscribe((responeData) => {
        this.step = 0;
        this.currentPlan = this.selectedStatements;
      });
    this.userSettingsService
      .saveSetting('setupActionPlan', '1')
      .subscribe((responeData) => {
      });
  }

  onAddTextarea() {
    this.textAreasList.push('text_area' + (this.textAreasList.length + 1));
  }
}
