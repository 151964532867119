<!--<nav class="navbar navbar-expand-sm navbar-light navbar-subnav-alt  navbar-subnav-alt d-md-none">
    <div class="container justify-content-center align-items-start">

        <button
      *ngIf="whichWeek == 'this'"
        class="mr-auto btn-nav-menu btn-nav-menu-sm"
        type="button"
        [routerLink]="['/']"
      >
      <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-left']"></fa-icon
        ></span>
        <span>Overview </span>
      </button>

        <div class="indicators position-absolute">
            <span>
                <span class="icon-wrap mr-1">
                <fa-icon [icon]="['fas', 'rectangle-portrait']" *ngIf="whichWeek == 'last'"></fa-icon>
                <fa-icon [icon]="['fal', 'rectangle-portrait']" *ngIf="whichWeek == 'this'"></fa-icon>

            </span>
              <span class="icon-wrap"
                ><fa-icon [icon]="['fal', 'rectangle-portrait']"></fa-icon
              ></span>
              <span class="icon-wrap ml-1"
                >
                <fa-icon [icon]="['fal', 'rectangle-portrait']" *ngIf="whichWeek == 'last'"></fa-icon>
                <fa-icon [icon]="['fas', 'rectangle-portrait']" *ngIf="whichWeek == 'this'"></fa-icon>

                </span
            ></span>
          </div>


      <button
      *ngIf="whichWeek == 'last'"
        class="ml-auto btn-nav-menu btn-nav-menu-sm"
        type="button"
        [routerLink]="['/']"
      >
        <span>Overview </span><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-right']"></fa-icon
            ></span>
      </button>
    </div>
  </nav>

<div class="container content-container pt-0" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
-->
    <h4 class="content-title">
      <span class="aside aside-left">{{days[0] | date : 'd MMM'}} - {{days[6] | date : 'd MMM'}}</span>
      <span *ngIf="whichWeek !=='summary'">{{whichWeek}} Week</span>
      <span *ngIf="whichWeek ==='summary'">7 DAY SUMMARY</span>
      <span class="aside aside-right" *ngIf="workouts">{{workouts.length}} active day<span *ngIf="workouts.length!=1">s</span></span>
    </h4>

    <div class="row mt-0">
      <div class="col col-xs">
        <table class="progress-table">
          <thead>

            <tr>
              <th></th>
              <th><span class="icon-wrap"><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon></span></th>
              <th><span class="icon-wrap"><fa-icon [icon]="['fas', 'walking']"></fa-icon></span></th>
              <th><span class="icon-wrap"><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon></span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let day of days">
              <td class="td-date" [ngClass]="{current: day === today}">
                <span class="day">{{day | date :'EEE'}}</span>
                <span class="num">{{day | date :'d'}}</span>
              </td>

              <td>
                  <span *ngIf="evaluations">
                    <a [routerLink]="['/evaluations', 'view', (evaluations | filterObjectArray : {'date_taken' : day} : true).evaluation_id]" *ngIf="(evaluations | filterObjectArray : {'date_taken' : day} : true).evaluation_id"><span class="circle" [ngClass]="{'bg-grey': !(evaluations | filterObjectArray : {'date_taken' : day} : true).zone, 'bg-success': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'g', 'bg-warning': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'a', 'bg-danger': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'r'}">&nbsp;</span></a>
                    <span *ngIf="!(evaluations | filterObjectArray : {'date_taken' : day} : true).zone" class="circle" [ngClass]="{'bg-grey': !(evaluations | filterObjectArray : {'date_taken' : day} : true).zone, 'bg-success': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'g', 'bg-warning': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'a', 'bg-danger': (evaluations | filterObjectArray : {'date_taken' : day} : true).zone == 'r'}">&nbsp;</span>
                  </span>
                <span *ngIf="!evaluations || !(evaluations | filterObjectArray : {'date_taken' : day} : true)">
                    <span class="circle bg-grey">&nbsp;</span>
                </span>
              </td>


              <td>
                <span *ngIf="workouts">
                  <a [routerLink]="['/exercise', 'workouts', 'view', (workouts | filterObjectArray : {'workout_date' : day} : true).workout_id]" *ngIf="(workouts | filterObjectArray : {'workout_date' : day} : true).workout_id"><span class="circle" [ngClass]="{'bg-primary': (workouts | filterObjectArray : {'workout_date' : day} : true).created, 'bg-grey': !(workouts | filterObjectArray : {'workout_date' : day} : true).created}">{{(workouts | filterObjectArray : {'workout_date' : day} : true).rep_score}} <span *ngIf="!(workouts | filterObjectArray : {'workout_date' : day} : true).rep_score">&nbsp;</span></span></a>
                  <span *ngIf="!(workouts | filterObjectArray : {'workout_date' : day} : true).rep_score" class="circle" [ngClass]="{'bg-primary': (workouts | filterObjectArray : {'workout_date' : day} : true).created, 'bg-grey': !(workouts | filterObjectArray : {'workout_date' : day} : true).created}">{{(workouts | filterObjectArray : {'workout_date' : day} : true).rep_score}} <span *ngIf="!(workouts | filterObjectArray : {'workout_date' : day} : true).rep_score">&nbsp;</span></span>
                </span>
              </td>
              <td>
                 <div *ngIf="goals">
                <div class="goal-status-container no-status">
                  <div class="goal-status">
                    
                    <a [routerLink]="['/goals']" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '1'}).length">
                      <span class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '1'}).length}"><fa-icon [icon]="['far', 'circle']"></fa-icon></span>
                    </a>
                    <span *ngIf="!(goals | filterObjectArray : {'review' : day, 'status': '1'}).length" class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '1'}).length}"><fa-icon [icon]="['far', 'circle']"></fa-icon></span>
                    
                    <span class="total" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '1'}).length">{{(goals | filterObjectArray : {'review' : day, 'status': '1'}).length}}</span>
                  </div>
                </div>

                <div class="goal-status-container no-status">
                  <div class="goal-status">
                    <a [routerLink]="['/goals']" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '2'}).length">
                      <span class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '2'}).length}"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
                    </a>
                    <span *ngIf="!(goals | filterObjectArray : {'review' : day, 'status': '2'}).length" class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '2'}).length}"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
                    <span class="total" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '2'}).length">{{(goals | filterObjectArray : {'review' : day, 'status': '2'}).length}}</span>
                  </div>
                </div>

                <div class="goal-status-container no-status">
                  <div class="goal-status">
                    <a [routerLink]="['/goals']" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '3'}).length">
                      <span class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '3'}).length}"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
                    </a>
                    <span *ngIf="!(goals | filterObjectArray : {'review' : day, 'status': '3'}).length" class="icon-wrap" [ngClass]="{'text-primary': (goals | filterObjectArray : {'review' : day, 'status': '3'}).length}"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
                    <span class="total" *ngIf="(goals | filterObjectArray : {'review' : day, 'status': '3'}).length">{{(goals | filterObjectArray : {'review' : day, 'status': '3'}).length}}</span>
                  </div>
                </div>
                </div>

              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="!inputWeek">
      <div class="col">
        <div class="card card-widget"  *ngIf="goals">
          <div class="card-body">
            <h5 class="card-title">
              <span class="icon-wrap"><i class="fas fa-bullseye-arrow"></i></span> Completed Goals
            </h5>
            <div *ngIf="goals.length < 1">
              No goals this week
            </div>
            <ul class="task-list">
                <li *ngFor="let goal of goals">
                    <span class="date">
                        <span class="day">{{goal.review | date : 'EEE'}}</span>
                        <span class="num">{{goal.review | date : 'd'}}</span>
                    </span>
                    <span class="task">{{goal.goal}}</span>
                    <span class="status">
                      <span class="icon-wrap"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
                    </span>
                </li>
            </ul>
        </div>
      </div>
    </div>
  <!--</div>-->
