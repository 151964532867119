
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { EvaluationsService } from './../evaluations/evaluations.service';

import { AuthService, AuthResponseData } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit{
  isLoading = false;
  error: string = null;

  constructor(private authService: AuthService, private router: Router, private evaluationsService: EvaluationsService) {}
  ngOnInit(): void{
    // are we logged in?
    let user = this.authService.user.getValue();
    if(user){
      if(this.authService.redirect){this.router.navigate([this.authService.redirect]);}
      else{this.router.navigate(['/dashboard']);}
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;
    const rememberMe = form.value.rememberMe;
    let authObs: Observable<AuthResponseData>;

    this.isLoading = true;

    authObs = this.authService.login(email, password, rememberMe);

    authObs.subscribe(
      resData => {
        this.isLoading = false;
        // do they need to evaluate?
        this.router.navigate(['/']);
      },
      errorMessage => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }
}
