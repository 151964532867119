import { Message } from './../messages/message.model';
import { MessagesService } from './../messages/messages.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ask',
  templateUrl: './ask.component.html',
  styleUrls: ['./ask.component.scss'],
})
export class AskComponent implements OnInit {
  @ViewChild('form', { static: false }) messageForm: NgForm;

  public messages: Message[] = [];
  isFetching = false;
  error = null;
  private errorSub: Subscription;

  constructor(private messagesService: MessagesService) {}

  ngOnInit(): void {
    this.errorSub = this.messagesService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isFetching = true;
    this.messagesService.fetchMessages('e').subscribe(
      (messages) => {
        this.isFetching = false;
        this.messages = messages;
      },
      (error) => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.messagesService
      .createMessage(this.messageForm.value.message, 'e')
      .subscribe(
        (responseData) => {
          const message = new Message(
            responseData.body.message_id,
            responseData.body.sender_id,
            responseData.body.recipient_id,
            responseData.body.message,
            responseData.body.category,
            responseData.body.is_admin,
            responseData.body.created,
            responseData.body.modified
          );
          this.messages.push(message);
          this.messageForm.reset();
        },
        (error) => {
          this.error = error.message;
        }
      );
  }
}
