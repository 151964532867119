import { ResourcesService } from './../../resources/resources.service';

import { environment } from './../../../environments/environment.prod';
import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-subnav-resources',
  templateUrl: './subnav-resources.component.html',
  styleUrls: ['./subnav-resources.component.scss']
})
export class SubnavResourcesComponent implements OnInit {

  @Output() toggleMenu = new EventEmitter();
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  environment = environment;
  contentSections;

  constructor(private resourcesService: ResourcesService) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.resourcesService.fetchAll(1).subscribe(
      contentSections => {
        this.isFetching = false;
        this.contentSections = contentSections;
      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

  onToggleMenu(){
    this.toggleMenu.emit();
  }

}
