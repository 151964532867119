<div class="container mobile-footer-padding content-container">
  <div class="row mt-0" *ngIf="step == 0">
    <div class="col">

<div *ngIf="userSettings?.exerciseLevel == '1'">

      <h4 class="content-title">
        <span class="aside aside-left" (click)="selectAll('bed')"
          >Select All</span
        >
        Bed Based
        <span class="aside aside-right"
          >{{ countByExerciseType("bed") }} Selected</span
        >
      </h4>

      <div class="row" *ngIf="exercises">
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'bed' }
                  | slice
                    : 0
                    : (exercises | filterObjectArray: { exercise_type: 'bed' })
                        .length /
                        2 +
                        1
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'bed' }
                  | slice
                    : (exercises | filterObjectArray: { exercise_type: 'bed' })
                        .length /
                        2 +
                        1
                    : (exercises | filterObjectArray: { exercise_type: 'bed' })
                        .length
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>



      <h4 class="content-title">
        <span class="aside aside-left" (click)="selectAll('sit')"
          >Select All</span
        >
        Sitting
        <span class="aside aside-right"
          >{{ countByExerciseType("sit") }} Selected</span
        >
      </h4>

      <div class="row" *ngIf="exercises">
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'sit' }
                  | slice
                    : 0
                    : (exercises | filterObjectArray: { exercise_type: 'sit' })
                        .length /
                        2 +
                        1
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'sit' }
                  | slice
                    : (exercises | filterObjectArray: { exercise_type: 'sit' })
                        .length /
                        2 +
                        1
                    : (exercises | filterObjectArray: { exercise_type: 'sit' })
                        .length
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="userSettings?.exerciseLevel == '2'">
      <h4 class="content-title mt-4">
        <span class="aside aside-left" (click)="selectAll('stand')"
          >Select All</span
        >
        Standing
        <span class="aside aside-right"
          >{{ countByExerciseType("stand") }} Selected</span
        >
      </h4>

      <div class="row" *ngIf="exercises">
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'stand' }
                  | slice
                    : 0
                    : (
                        exercises
                        | filterObjectArray: { exercise_type: 'stand' }
                      ).length /
                        2 +
                        1
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="exercise-list">
            <li
              *ngFor="
                let exercise of exercises
                  | filterObjectArray: { exercise_type: 'stand' }
                  | slice
                    : (
                        exercises
                        | filterObjectArray: { exercise_type: 'stand' }
                      ).length /
                        2 +
                        1
                    : (
                        exercises
                        | filterObjectArray: { exercise_type: 'stand' }
                      ).length
              "
              (click)="selectExercise(exercise)"
              [ngClass]="{
                selected: selectedExercises.indexOf(exercise.exercise_id) !== -1
              }"
            >
              {{ exercise.name }}
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedExercises.indexOf(exercise.exercise_id) !== -1"
                ></fa-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <!--
        <h4 class="content-title mt-4">
            Optional
          </h4>

          <div class="row">
              <div class="col">
                  <ul class="exercise-list">
                      <li>
                          Add to Favourites
                          <span class="icon-wrap">
                            <i class="far fa-square"></i>
                          </span>
                      </li>
                  </ul>
              </div>

          </div>
        -->

      <button
        class="btn btn-primary btn-block btn-lg mt-4"
        (click)="step = step + 1"
        [disabled]="selectedExercises.length < 1"
      >
        Begin Workout
      </button>
    </div>
  </div>
  <!-- the workout -->

  <div class="row mt-0" *ngIf="step > 0 && step <= selectedExercises.length">
    <div class="col">
      <form action="" class="card-form">
        <h4 class="content-title">
          {{
            (
              exercises
              | filterObjectArray
                : { exercise_id: selectedExercises[step - 1] }
                : true
            ).exercise_type
          }}
        </h4>

        <div class="card">
          <div class="card-body">
            <div class="row mt-0">
              <div class="col col-7 text-left">
                <label class="label-lg">
                  {{
                    (
                      exercises
                      | filterObjectArray
                        : { exercise_id: selectedExercises[step - 1] }
                        : true
                    ).name
                  }}
                </label>
                <div class="instruction">Enter your rep score</div>
              </div>
              <div class="col col-3 text-right">
                <input
                  type="text"
                  pattern="\d*"
                  maxlength="2"
                  class="form-control text-center"
                  name="reps_{{ selectedExercises[step - 1] }}"
                  id="reps_{{ selectedExercises[step - 1] }}"
                  [(ngModel)]="repData[selectedExercises[step - 1]]"
                  placeholder="0"
                  #repDataEl
                  #repDataElV="ngModel"
                  autofocus="true"
                />
              </div>
              <div class="col col-2 text-left text-middle">
                <label>Reps</label>
              </div>
            </div>
            <hr class="divider" />
            <div class="text-center">
              <img src="assets/img/exercise-man.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>

        <h4 class="content-title content-title-nu mt-3">
          <span class="aside aside-left"
            >Exercise {{ step }} of {{ selectedExercises.length }}</span
          >
          &nbsp;
          <span class="aside aside-right"
            >{{ selectedExercises.length - step }} exercise<span
              *ngIf="selectedExercises.length - step != 1"
              >s</span
            >
            remaining</span
          >
        </h4>
        <button
          class="btn btn-primary btn-block btn-lg mt-4"
          (click)="nextStep()"
          [disabled]="!repDataElV.valid"
        >
          <span *ngIf="step < selectedExercises.length">Next</span>
          <span *ngIf="step === selectedExercises.length"
            >Complete Workout</span
          >
        </button>
      </form>
    </div>
  </div>

  <div class="row mt-0" *ngIf="step > selectedExercises.length">
    <div class="col">
      <h4 class="content-title">workout Complete</h4>

      <div class="card card-widget mt-4" *ngIf="userSettings?.exerciseLevel == '1'">
        <div class="card-body">
          <div class="row mt-0">
            <div class="col text-left">
              <h5 class="card-title card-title-u">
                Bed based
                <span class="float-right"
                  >{{ repData | getTypeReps: "bed":exercises }} Reps</span
                >
              </h5>
            </div>
          </div>
          <div class="row text-left mt-0">
            <div class="col small">
              <span
                *ngFor="
                  let exercise of exercises
                    | filterObjectArray: { exercise_type: 'bed' }
                "
              >
                <p class="mb-1" *ngIf="repData[exercise.exercise_id]">
                  {{ exercise.name }}
                  <span class="float-right">{{
                    repData[exercise.exercise_id]
                  }}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div class="card card-widget mt-4">
        <div class="card-body">
          <div class="row mt-0">
            <div class="col text-left">
              <h5 class="card-title card-title-u">
                Sitting
                <span class="float-right"
                  >{{ repData | getTypeReps: "sit":exercises }} Reps</span
                >
              </h5>
            </div>
          </div>
          <div class="row text-left mt-0">
            <div class="col small">
              <span
                *ngFor="
                  let exercise of exercises
                    | filterObjectArray: { exercise_type: 'sit' }
                "
              >
                <p class="mb-1" *ngIf="repData[exercise.exercise_id]">
                  {{ exercise.name }}
                  <span class="float-right">{{
                    repData[exercise.exercise_id]
                  }}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-widget mt-4" *ngIf="userSettings?.exerciseLevel == '2'">
        <div class="card-body">
          <div class="row mt-0">
            <div class="col text-left">
              <h5 class="card-title card-title-u">
                Standing
                <span class="float-right"
                  >{{ repData | getTypeReps: "stand":exercises }} Reps</span
                >
              </h5>
            </div>
          </div>
          <div class="row text-left mt-0">
            <div class="col small">
              <span
                *ngFor="
                  let exercise of exercises
                    | filterObjectArray: { exercise_type: 'stand' }
                "
              >
                <p class="mb-1" *ngIf="repData[exercise.exercise_id]">
                  {{ exercise.name }}
                  <span class="float-right">{{
                    repData[exercise.exercise_id]
                  }}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="jumbotron text-center mt-4">
        <h5>TOTAL REPS SCORE</h5>
        <span class="circle bg-dark">{{
          repData | getTypeReps: "total":exercises
        }}</span>
      </div>
    </div>
  </div>

  <!-- end the workout -->
</div>
