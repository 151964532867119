import { WorkoutsService } from './../../exercise/workouts.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import { EvaluationsService } from './../../evaluations/evaluations.service';
import { GoalsService } from './../../goals/goals.service';
import { Evaluation } from 'src/app/evaluations/evaluation.model';
import { Goal } from 'src/app/goals/goal.model';
import { Workout } from 'src/app/exercise/workout.model';
import { SwipeService } from '../../shared/swipe.service';

@Component({
  selector: 'app-dashboard-week',
  templateUrl: './dashboard-week.component.html',
  styleUrls: ['./dashboard-week.component.scss'],
})
export class DashboardWeekComponent implements OnInit {
  whichWeek: string;
  days = [];
  today = moment().format('YYYY-MM-DD');
  goals: Goal[];
  evaluations: Evaluation[];
  workouts: Workout[];
  errors: string[];
  @Input() inputWeek;
  constructor(
    private route: ActivatedRoute,
    private goalsService: GoalsService,
    private evaluationsService: EvaluationsService,
    private workoutsService: WorkoutsService,
    private swipeService: SwipeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.inputWeek){
      this.whichWeek = this.inputWeek;
    }else{
      this.whichWeek = this.route.snapshot.data['week'];
    }
    switch (this.whichWeek) {
      case 'last':
        // get the previous week
        this.days.push(
          moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
        );
        for (let i = 0; i < 6; i++) {
          this.days.push(
            moment(this.days[i]).add(1, 'days').format('YYYY-MM-DD')
          );
        }
        break;
      case 'this':
        // get the previous week
        this.days.push(moment().startOf('isoWeek').format('YYYY-MM-DD'));
        for (let i = 0; i < 6; i++) {
          this.days.push(
            moment(this.days[i]).add(1, 'days').format('YYYY-MM-DD')
          );
        }
        break;
        case 'summary':
          // get the last 7 days
          this.days.push(moment().subtract(6, 'days').format('YYYY-MM-DD'));
          for (let i = 0; i < 6; i++) {
            this.days.push(
              moment(this.days[i]).add(1, 'days').format('YYYY-MM-DD')
            );
          }
          break;
    }

    this.goalsService.fetchGoals().subscribe(
      (goals) => {
        this.goals = [];
        goals.forEach( (goal) => {
          if (goal.review >= this.days[0] && goal.review <= this.days[6]){
            this.goals.push(goal);
          }
        })
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );

    this.evaluationsService.fetchEvaluations().subscribe(
      (evaluations) => {
        this.evaluations = [];
        evaluations.forEach( (evaluation) => {
          if (evaluation.date_taken >= this.days[0] && evaluation.date_taken <= this.days[6]){
            this.evaluations.push(evaluation);
          }
        })
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );

    this.workoutsService.fetchWorkouts().subscribe(
      (workouts) => {
        this.workouts = [];
        workouts.forEach( (workout) => {
          if (workout.workout_date >= this.days[0] && workout.workout_date <= this.days[6]){
            this.workouts.push(workout);
          }
        })
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }
  swipe(e: TouchEvent, when: string): void {
    const swipeDirection = this.swipeService.swipe(e, when);
    switch (swipeDirection) {
      case 'previous':
        if (this.whichWeek === 'this'){
          this.router.navigate(['/dashboard']);
        }
        break;
      case 'next':
        if (this.whichWeek === 'last'){
          this.router.navigate(['/dashboard']);
        }
        break;
    }
  }

}
