import { GoalsService } from './goals.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { Goal } from './goal.model';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {

  public goals: Goal[] = [];
  isFetching = false;
  error = null;
  private errorSub: Subscription;

  constructor(private http: HttpClient, private goalsService: GoalsService ) { }

  ngOnInit() {
    this.errorSub = this.goalsService.error.subscribe(errorMessage => {
      this.error = errorMessage;
    });

    this.isFetching = true;
    this.goalsService.fetchGoals().subscribe(
      goals => {
        this.isFetching = false;
        this.goals = goals;
      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

  countByStatus(status){
    return this.goals.filter((obj) => +obj.status === status).length;
  }

}
