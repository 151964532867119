<div class="accordion" id="resourcesSubnav">
   
    <div class="card"  *ngFor="let contentSection of contentSections">
      <div
        class="card-header"
        id="headingTwo"
      >
        <button
          class="btn btn-link"
          type="button"
          [routerLink]="['/resources', contentSection.content_section_id]"
          routerLinkActive="active"
          #linkTwo="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.collapsed]="!linkTwo.isActive"
          (click)="onToggleMenu()"
        >
        {{contentSection.title}}
        </button>
      </div>
    </div>




  </div>
