<app-subnav [title]="'Ask a Professional'" [section]="'ask'"></app-subnav>
<div class="container content-wrap mobile-footer-padding nmh relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div *ngIf="messages">
    <div *ngFor="let message of messages">
      <div
        class="speech-bubble"
        [ngClass]="{
          'speech-bubble-left': message.is_admin,
          'speech-bubble-right': !message.is_admin
        }"
      >
        {{ message.message }}
      </div>
      <div
        class="msg-details"
        [ngClass]="{
          'text-right': !message.is_admin
        }"
      >
        <strong
          ><span *ngIf="message.is_admin">Self-Breathe Team</span
          ><span *ngIf="!message.is_admin">You</span></strong
        >
        | {{ message.created | parseDate | date: "EEE dd MMM yyyy, h.mm aa" }}
      </div>
    </div>
  </div>
  <div *ngIf="messages.length < 1">
    <p class="text-center pt-3 pb-3">You haven't asked any questions yet.</p>
  </div>
</div>

<div class="chat-input-container hfn">
  <div class="inner-addon right-addon">
    <form class="message-form" (ngSubmit)="onSubmit()" #form="ngForm">
      <button
        class="btn btn-primary addon btn-icon-only d-lg-none d-xl-none"
        [disabled]="!message.valid"
      >
        <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'paper-plane']"
            [fixedWidth]="true"
          ></fa-icon
        ></span>
      </button>
      <label for="message" class="sr-only">Your Message</label>
      <textarea
        type="text"
        class="chat-input form-control form-control-lg"
        placeholder="Write a message..."
        name="message"
        id="message"
        rows="1"
        ngModel
        #message="ngModel"
        required
        maxlength="65535"
        minlength="3"
        autocomplete="off"
      ></textarea>

      <button
        class="btn btn-primary btn-block mt-2 d-none d-sm-none d-md-none d-xl-block d-lg-block"
        [disabled]="!message.valid"
      >
        Send Message
        <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'paper-plane']"
            [fixedWidth]="true"
          ></fa-icon
        ></span>
      </button>
    </form>
  </div>
</div>
