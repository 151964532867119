<!--nav class="navbar navbar-expand-sm navbar-light navbar-subnav-alt d-md-none">
  <div class="container justify-content-center align-items-start">
    <button
      class="mr-auto btn-nav-menu btn-nav-menu-sm"
      type="button"
      [routerLink]="['/dashboard', 'last-week']"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-left']"></fa-icon
      ></span>
      <span> Last Week</span>
    </button>
    <div class="indicators position-absolute">
      <span
        ><span class="icon-wrap mr-1"
          ><fa-icon
            [icon]="['fal', 'rectangle-portrait']"
            [routerLink]="['/dashboard', 'last-week']"
          ></fa-icon
        ></span>
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'rectangle-portrait']"></fa-icon
        ></span>
        <span class="icon-wrap ml-1"
          ><fa-icon
            [icon]="['fal', 'rectangle-portrait']"
            [routerLink]="['/dashboard', 'this-week']"
          ></fa-icon></span
      ></span>
    </div>
    <button
      class="ml-auto btn-nav-menu btn-nav-menu-sm"
      type="button"
      [routerLink]="['/dashboard', 'this-week']"
    >
      <span>This Week </span
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-right']"></fa-icon
      ></span>
    </button>
  </div>
</nav>-->

<div class="container mobile-footer-padding content-container pt-0 relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="row mb-3">
    <div class="col-12">
      <div
      >
        <div class="row">
          <div class="col">
            <div class="card card-widget">
              <div class="card-body relative">
                <h5 class="card-title">Getting Started</h5>
                <a (click)="onPrevTask()" class="float-left db-task-prev">
                  <span class="icon-wrap icon-button" aria-label="Previous db-task"
                    ><fa-icon [icon]="['far', 'chevron-left']"></fa-icon></span
                ></a>

                <a (click)="onNextTask()" class="float-right db-task-next">
                  <span class="icon-wrap icon-button" aria-label="Next db-task"
                    ><fa-icon [icon]="['far', 'chevron-right']"></fa-icon></span
                ></a>
                <swiper [(index)]="sliderIndex" [config]="swiperConfig">
                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img src="/assets/img/video-still.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">1</div>
                        <div class="db-task-title">Watch Opening Video</div>
                        <div class="db-task-text">
                          Learn about the SELF-BREATHE programme and how it will help your breathlessness.
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/welcome']"
                        >
                          Play Video
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img src="/assets/img/video-still-evaluate.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">2</div>
                        <div class="db-task-title">Complete Evaluation</div>
                        <div class="db-task-text">
                          Keep track of your breathlessness and how you are feeling
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/evaluations', 'evaluate-today']"
                        >
                          Take Evaluation
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still-evaluate.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">

                        <img src="/assets/img/video-still-techniques.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">3</div>
                        <div class="db-task-title">
                          View techniques to help you
                        </div>
                        <div class="db-task-text">
                          Teaches you techniques specifically aimed to improve your breathing and breathlessness 
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/techniques']"
                        >
                          View techniques
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still-techniques.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img src="/assets/img/video-still-plan.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">4</div>
                        <div class="db-task-title">Setup your action plan</div>
                        <div class="db-task-text">
                          Create your own breathlessness action plan, to help if you have a breathlessness attack. 
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/techniques', 'action-plan']"
                        >
                          Setup plan
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still-plan.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img src="/assets/img/video-still-exercise.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">5</div>
                        <div class="db-task-title">Start getting active</div>
                        <div class="db-task-text">
                          Create your own personalised home exercise programme to improve your activity levels and breathlessness
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/exercise']"
                        >
                          Get Active
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still-exercise.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img src="/assets/img/video-still-goals.jpg" alt="" class="img-fluid d-block d-sm-none b-2 mb-4" />
                        <div class="db-task-num">6</div>
                        <div class="db-task-title">Read about goal setting</div>
                        <div class="db-task-text">
                          Set yourself  a personal goal / target to achieve while using SELF-BREATHE 
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/goals', 'about']"
                        >
                          Read about goals
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img src="/assets/img/video-still-goals.jpg" alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                </swiper>
                <div class="row mt-2 mb-1">
                  <div class="col col-xs text-center">
                    <a  href="" class="icon-wrap mr-1 pointer" tabindex="0" (click)="$event.preventDefault();onSetIndex(0)" [ngClass]="{'text-primary': sliderIndex==0, 'text-menu-bg': sliderIndex!=0}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                    <a  href=""  class="icon-wrap mr-1 pointer" tabindex="0"  (click)="$event.preventDefault();onSetIndex(1)" [ngClass]="{'text-primary': sliderIndex==1, 'text-menu-bg': sliderIndex!=1}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                    <a  href=""  class="icon-wrap mr-1 pointer" tabindex="0"  (click)="$event.preventDefault();onSetIndex(2)" [ngClass]="{'text-primary': sliderIndex==2, 'text-menu-bg': sliderIndex!=2}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                    <a  href=""  class="icon-wrap mr-1 pointer" tabindex="0"  (click)="$event.preventDefault();onSetIndex(3)" [ngClass]="{'text-primary': sliderIndex==3, 'text-menu-bg': sliderIndex!=3}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                    <a  href=""  class="icon-wrap mr-1 pointer" tabindex="0"  (click)="$event.preventDefault();onSetIndex(4)" [ngClass]="{'text-primary': sliderIndex==4, 'text-menu-bg': sliderIndex!=4}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                    <a  href=""  class="icon-wrap mr-1 pointer" tabindex="0"  (click)="$event.preventDefault();onSetIndex(5)" [ngClass]="{'text-primary': sliderIndex==5, 'text-menu-bg': sliderIndex!=5}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row  h-100" *ngIf="daysSinceEvaluation!=0 || daysSinceWorkout!=0 || goalsToReview.length!=0">
          <div class="col">
            <div class="card card-widget">
              <div class="card-body">
                <h5 class="card-title">Reminders</h5>

                <ul class="task-list-alt">
                  <li *ngIf="daysSinceEvaluation!=0">
                    <a 
                    [routerLink]="['/evaluations', 'evaluate-today']">
                    <span class="task-icon">
                      <fa-icon [icon]="['fas', 'traffic-light']"></fa-icon>
                    </span>
                    <span class="task"
                    ><span class="task-title">Evaluation</span>
                      <span class="task-message" *ngIf="latestEvaluation">You last took an evaluation <strong>{{daysSinceEvaluation}} days ago</strong> and were in the <strong><span *ngIf="latestEvaluation.zone === 'g'">Green</span><span *ngIf="latestEvaluation.zone === 'a'">Amber</span><span *ngIf="latestEvaluation.zone === 'r'">Red</span> Zone</strong>.</span>
                      </span
                    >
                    <span class="status">
                      <span class="icon-wrap"
                        >  <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon></span>
                    </span></a>
                  </li>
                  <li *ngIf="goalsToReview.length!=0"> <a [routerLink]="['/goals']">
                    <span class="task-icon">
                      <fa-icon 
                      [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                    </span>
                    <span class="task"
                      ><span class="task-title">Review Goals</span>
                      <span class="task-message">You currently have <strong>{{goalsToReview.length}} goal<span *ngIf="goalsToReview.length!=1">s</span></strong> to review.</span>
                      </span
                    >
                    <span class="status">
                      <span class="icon-wrap"
                        >  <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon></span>
                    </span></a>
                  </li>
                  <li *ngIf="daysSinceWorkout!=0"> <a [routerLink]="['/exercise']">
                    <span class="task-icon">
                      <fa-icon  [icon]="['fas', 'walking']"></fa-icon>
                    </span>
                    <span class="task"
                    ><span class="task-title">Do a workout</span>
                      <span class="task-message">You have have not logged a workout on the Self-Breathe Programme for <strong>{{daysSinceWorkout}} days</strong>.</span>
                      </span
                    >
                    <span class="status">
                      <span class="icon-wrap"
                        >  <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon></span>
                    </span></a>
                  </li>
                  
                </ul>
                
              </div>
            </div>
          </div>
        </div>

        <div class="row  h-100">
          <div class="col-6 col-sm-6 col-xs-6 mb-3">
            <div class="card card-widget  h-100">
              <div class="card-body pr-1 pl-1">
                <h5 class="card-title">Latest Evaluation</h5>

                <div *ngIf="!latestEvaluation">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon
                    ></span>
                    <span class="circle bg-grey">&nbsp;</span>
                  </div>

                  <button
                    class="btn btn-outline-primary"
                    [routerLink]="['/evaluations', 'evaluate-today']"
                  >
                    Take Today's
                  </button>
                </div>

                <div *ngIf="latestEvaluation">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon
                    ></span>
                    <span
                      class="circle bg-success"
                      [ngClass]="{
                        'bg-success': latestEvaluation.zone == 'g',
                        'bg-warning': latestEvaluation.zone == 'a',
                        'bg-danger': latestEvaluation.zone == 'r'
                      }"
                      >&nbsp;</span
                    >
                  </div>
                  <div class="widget-updated">
                    {{ latestEvaluation.date_taken | date: "E d LLL" }}
                  </div>
                  <button
                    class="btn btn-outline-primary"
                    [routerLink]="['/evaluations', 'evaluate-today']"
                  >
                    <span *ngIf="evlauationToday">Retake</span>
                    <span *ngIf="!evlauationToday">Take Today's</span>
                  </button>
                </div>



              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-xs-6 mb-3">
            <div class="card card-widget  h-100">
              <div class="card-body pr-1 pl-1">
                <h5 class="card-title">Latest Workout</h5>
                <div *ngIf="latestWorkout">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'walking']"></fa-icon></span
                    ></span>
                    <span class="circle bg-primary">{{
                      latestWorkout.rep_score
                    }}</span>
                  </div>
                  <div class="widget-updated">
                    {{ latestWorkout.workout_date | date: "E d LLL" }}
                  </div>
                </div>
                <div *ngIf="!latestWorkout">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'walking']"></fa-icon></span
                    ></span>
                    <span class="circle bg-grey">&nbsp;</span>
                  </div>
                  <div class="widget-updated">
                   
                  </div>
                </div>

                <button
                  class="btn btn-outline-primary"
                  [routerLink]="['/exercise', 'create-workout']"
                >
                  Start New Workout
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xs-12 mb-3">
            <div class="card card-widget  h-100">
              <div class="card-body">
                <h5 class="card-title">My Goals</h5>
                <div class="widget-icons">
                  <span class="widget-icon" 
                    ><span class="icon-wrap"
                      ><fa-icon 
                        [icon]="['fas', 'bullseye-arrow']"
                      ></fa-icon></span
                  ></span>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['far', 'circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus: 1 }}</span>
                    </div>
                    <span class="goal-status-label">In Progress</span>
                  </div>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['far', 'check-circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus: 2 }}</span>
                    </div>
                    <span class="goal-status-label">Partially</span>
                  </div>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus: 3 }}</span>
                    </div>
                    <span class="goal-status-label">Achieved</span>
                  </div>
                </div>
                <button
                  class="btn btn-outline-primary"
                  [routerLink]="['/goals', 'create']"
                >
                  Create New Goal
                </button>

                <hr />

                <h5 class="card-title">
                  <span class="icon-wrap"
                    ><i class="fas fa-bullseye-arrow"></i
                  ></span>
                  GOALS TO REVIEW
                </h5>
                <p *ngIf="goalsToReview.length==0">No goals to review</p>
                <ul class="task-list">
                  <li *ngFor="let goal of goalsToReview">
                    <a [routerLink]="['/goals', 'view', goal.goal_id]">
                    <span class="date">
                      <span class="day">{{goal.review | date : 'EEE'}}</span>
                      <span class="num">{{goal.review | date : 'd'}}</span>
                    </span>
                    <span class="task"
                      >{{goal.goal}}</span
                    >
                    <span class="status">
                      <span class="icon-wrap"
                        >
                        <fa-icon [icon]="['far', 'circle']" *ngIf="+goal.status===1"></fa-icon>
                        <fa-icon [icon]="['far', 'check-circle']" *ngIf="+goal.status===2"></fa-icon>
                        <fa-icon [icon]="['fas', 'check-circle']" *ngIf="+goal.status===3"></fa-icon>
                      </span>
                    </span>
                  </a>
                  </li>
                  
                </ul>

                <button
                class="btn btn-outline-primary"
                [routerLink]="['/goals', 'all']"
              >
                View All Goals
              </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="card card-widget h-100">
              <div class="card-body">
                <app-dashboard-week
                  [inputWeek]="'summary'"
                ></app-dashboard-week>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 d-none d-md-block"></div>
        </div>
      </div>
    </div>
  </div>
</div>
