import { UserSettingsService } from './../../services/user-settings.service';
import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { WorkoutsService } from '../workouts.service';
import { Workout } from '../workout.model';

@Component({
  selector: 'app-exercise-workouts',
  templateUrl: './exercise-workouts.component.html',
  styleUrls: ['./exercise-workouts.component.scss'],
})
export class ExerciseWorkoutsComponent implements OnInit {
  workouts: Workout[];
  errors: string[];
  isFetching;
  userSettings;

  constructor(
    private workoutsService: WorkoutsService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    forkJoin({
      workouts: this.workoutsService.fetchWorkouts(),
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.workouts = responseData.workouts.reverse();

      this.userSettings = responseData.userSettings;
      this.isFetching = false;
    });
  }
}
