export class Goal {
  public goal_id?: number;
  public userId?: number;
  public goal: string;
  public how: string;
  public support: string;
  public review: string;
  public status?: number;
  public created?: string;
  public modified?: string;

  constructor(
    goal_id?: number,
    userId?: number,
    goal?: string,
    how?: string,
    support?: string,
    review?: string,
    status?: number,
    created?: string,
    modified?: string
  ) {
    this.goal_id = goal_id;
    this.userId = userId;
    this.goal = goal;
    this.how = how;
    this.support = support;
    this.review = review;
    this.status = status;
    this.created = created;
    this.modified = modified;
  }
}
