import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { sha256, sha224 } from 'js-sha256';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorSignatureService implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const d = new Date();
    const n = String(d.getTime());
    const signature = sha256(request.url + n);
    const modifiedReq = request.clone({
      setHeaders: {
        'Signature' :  signature,
        'Signature-ref': n
    }
   });
    return next.handle(modifiedReq);
  }
}
