
<div class="container mobile-footer-padding content-wrap relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  
  <div class="img-container">
  <img
    [src]="page?.image_filename | getThumbnail: 'wide'"
    alt="Image showing {{ page?.title }}"
    class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none"
    *ngIf="page?.image_filename"
  />
  <img
    [src]="page?.image_filename | getThumbnail: 'wide'"
    alt="Image showing {{ page?.title }}"
    class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block drop-shadow rounded full"
    *ngIf="page?.image_filename"
  />
  <div *ngIf="page?.page_type_id === '2'">
    <video width="100%" poster="{{page.content.posterUrl}}" controls preload class="fade-in rounded drop-shadow">
      <source src="{{page.content.videoUrl}}" type="video/mp4">
      <track src="{{page.content.captionsUrl}}" kind="subtitles" srclang="en" label="English" default *ngIf="page.content.captionsUrl">
     </video>
  </div>
</div>
  <div class="container mt-4 container-resource-page">
    <h3 class="sm-title mb-4 has-prev-next">
      <a (click)="onPrev()" class="prev pointer" *ngIf="prevPage?.content_page_id" [routerLink]="['']">
        <span class="sr-only">Previous Page</span>
        <span class="icon-wrap">
          <fa-icon
              [icon]="['far', 'arrow-alt-left']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
      </a>
      {{page?.title}}
      <a (click)="onNext()" class="next pointer" *ngIf="nextPage?.content_page_id" [routerLink]="['']">
        <span class="sr-only">Next Page</span>
        <span class="icon-wrap">
        <fa-icon
            [icon]="['far', 'arrow-alt-right']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
    </h3>

    <div (click)="onContentClick($event)">
      <div [innerHTML]="page?.content | safeHtml" *ngIf="page?.page_type_id === '1'"></div>
      <div *ngIf="page?.page_type_id === '2'">
        <div [innerHTML]="page?.content.content | safeHtml"></div>
      </div>
    </div>
    
    
    
    
    

    <div class="row prev-next">
      <div class="col text-left">
        <a (click)="onPrev()" class="prev float-left pointer" *ngIf="prevPage?.content_page_id" [routerLink]="['']">
          
          <span class="icon-wrap">
            <fa-icon
                [icon]="['far', 'arrow-alt-left']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
            Previous
        </a>
      </div>
      <div class="col text-right">
        <a (click)="onNext()" class="prev float-right pointer" *ngIf="nextPage?.content_page_id" [routerLink]="['']">
          Next
          <span class="icon-wrap">
          <fa-icon
              [icon]="['far', 'arrow-alt-right']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
