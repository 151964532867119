import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  public page = 'terms';
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.page = this.route.snapshot.data['page'];
    this.route.data.subscribe(data => {
      this.page = data['page'];
    });
  }

}
