import { UserSettingsService } from './../../services/user-settings.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-goals-about',
  templateUrl: './goals-about.component.html',
  styleUrls: ['./goals-about.component.scss'],
})
export class GoalsAboutComponent implements OnInit {
  step = 1;
  constructor(private router: Router, private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
  }

  nextStep(): void {
    this.step++;
    if (this.step === 4) {
      this.userSettingsService
    .saveSetting('aboutGoal', 1)
    .subscribe((responeData) => {});
      this.router.navigate(['/goals']);
    }
  }
}
