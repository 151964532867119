import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent implements OnInit {
  isLoading = false;
  error: string = null;
  sent = false;

  constructor(private authService: AuthService, private router: Router) {}
  ngOnInit(): void {
    // are we logged in?
    let user = this.authService.user.getValue();
    if (user) {
      if (this.authService.redirect) {
        this.router.navigate([this.authService.redirect]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    let authObs: Observable<any>;
    this.isLoading = true;
    authObs = this.authService.forgottenPassword(email);

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        // do they need to evaluate?
        this.sent = true;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }
}
