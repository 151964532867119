import { Component, OnInit } from '@angular/core';
import { EvaluationsService } from '../evaluations.service';
import {Evaluation} from '../evaluation.model';

@Component({
  selector: 'app-evaluations-all',
  templateUrl: './evaluations-all.component.html',
  styleUrls: ['./evaluations-all.component.scss']
})
export class EvaluationsAllComponent implements OnInit {

  public evaluations: Evaluation[] = [];
  public errors = [];

  constructor(private evaluationsService: EvaluationsService) { }

  ngOnInit(): void {
    this.evaluationsService.fetchEvaluations().subscribe(
      evaluations => {
        this.evaluations = evaluations;
      },
      error => {
        // this.errors.push(error.message);
      }
    );
  }

}
