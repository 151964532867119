import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ExercisesService } from './../exercises.service';
import { WorkoutsService } from './../workouts.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exercise-workout-view',
  templateUrl: './exercise-workout-view.component.html',
  styleUrls: ['./exercise-workout-view.component.scss'],
})
export class ExerciseWorkoutViewComponent implements OnInit {
  workout;
  repData = {};
  exercises;
  isFetching;
  error;
  constructor(
    private exercisesService: ExercisesService,
    private workoutsService: WorkoutsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    forkJoin({
      workout: this.workoutsService.fetch(this.route.snapshot.params['id']),
      exercises: this.exercisesService.fetchExercises(),
    }).subscribe((responseData) => {
      this.exercises = responseData.exercises;

      this.workout = responseData.workout;
      const repsData = JSON.parse(this.workout.rep_data);
      for (let key in repsData['bed']) {
        this.repData[key] = repsData['bed'][key];
      }
      for (let key in repsData['sitting']) {
        this.repData[key] = repsData['sitting'][key];
      }
      for (let key in repsData['standing']) {
        this.repData[key] = repsData['standing'][key];
      }
    });
  }



  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.workoutsService.delete(this.workout.workout_id).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your workout has been deleted.', 'success');
            this.router.navigate(['/exercise', 'workouts']);
          },
          (error) => {
            this.isFetching = false;
            this.error = error.message;
            Swal.fire('Error', 'There was an error deleting the workout.', 'error');
          }
        );
      }
    });
  }
}
