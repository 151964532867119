import { GoalsService } from '../goals.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { Goal } from '../goal.model';

@Component({
  selector: 'app-goals',
  templateUrl: './goals-all.component.html',
  styleUrls: ['./goals-all.component.scss'],
})
export class GoalsAllComponent implements OnInit {
  public goals: Goal[] = [];
  isFetching = false;
  error = null;
  filtered = {
    1: false,
    2: false,
    3: false,
  };
  private errorSub: Subscription;

  constructor(private http: HttpClient, private goalsService: GoalsService) {}

  ngOnInit() {
    this.errorSub = this.goalsService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isFetching = true;
    this.goalsService.fetchGoals().subscribe(
      (goals) => {
        this.isFetching = false;
        this.goals = goals;
      },
      (error) => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

  filteredGoals(goals) {
    const filteredGoals = [];
    if (goals){
      goals.forEach((goal, index) => {
        if (this.filtered[1] === false && this.filtered[2] === false && this.filtered[3] === false){
          filteredGoals.push(goal);
        }
        else{
          if (this.filtered[goal.status]  === true){
            filteredGoals.push(goal);
          }
        }
      });

      return filteredGoals;
    }
  }
  filter(status) {
    this.filtered[status] = !this.filtered[status];
  }

  countByStatus(status) {
    return this.goals.filter((obj) => +obj.status === status).length;
  }
}
