<app-subnav [title]="'Update your details'"></app-subnav>
<div class="container-fluid mobile-footer-padding content-container fh ">
  <div class="row" *ngIf="userData">
    <div class="col-md-12">

      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" style="text-align: center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!updated"
        class="goal-form"
        name="update-details"
      >
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            class="form-control"
            [ngModel]="userData.firstName"
            name="firstName"
            placeholder="First Name"
            required
          />
        </div>

        <div class="form-group">
          <label for="lastName">Last Name</label>

          <input
            type="text"
            id="lastName"
            class="form-control"
            [ngModel]="userData.lastName"
            name="lastName"
            placeholder="Last Name"
            required
          />
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            [ngModel]="userData.email"
            name="email"
            placeholder="Email"
            required
            email
          />
        </div>

        <div *ngIf="userSettings">
          <div class="form-group" *ngIf="userSettings.exerciseLevel">
            <label for="exerciseLevel">Exercise Level</label>
            <select
              class="form-control"
              id="exerciseLevel"
              name="exerciseLevel"
              [ngModel]="userSettings.exerciseLevel"
            >
              <option value="1">Bed Based and Sitting</option>
              <option value="2">Sitting and Standing</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          [disabled]="!authForm.valid || isLoading"
          class="btn btn-primary btn-block"
        >
          Update
        </button>
      </form>
      <p *ngIf="updated">
        If your email exists you will have been sent a link to reset your
        password.
      </p>

      <hr />
    </div>
  </div>
</div>
