import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDate',
})
export class ParseDatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      const year = value.substr(0, 4);
      const month = value.substr(5, 2);
      const day = value.substr(8, 2);
      const time = value.substr(11, 8);

      return year + '/' + month + '/' + day + ' ' + time;
    }
  }
}
