<div class="bg-dark">
  <div class="container-fluid content-container fh login">
    <div class="login-logo">
      <img src="assets/img/logo-white.svg" />
    </div>

    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="alert alert-danger" *ngIf="error">
          <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading" style="text-align: center;">
          <app-loading-spinner></app-loading-spinner>
        </div>

       
        <form
          #authForm="ngForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading"
        >
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><fa-icon
                    [icon]="['fal', 'user']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </div>
              <input
                type="email"
                id="email"
                class="form-control"
                ngModel
                name="email"
                placeholder="Email"
                required
                email
              />
            </div>


            <!--label for="email">Email address</label-->
          </div>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><fa-icon
                    [icon]="['fal', 'key']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </div>
              <input
                type="password"
                id="password"
                class="form-control"
                ngModel
                name="password"
                required
                minlength="6"
                placeholder="Password"
              />
            </div>
            <!--label for="inputPassword">Password</label-->
          </div>

          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary btn-block btn-lg"
          >
            Sign in
          </button>
          <hr />
          <div class="row">
            <div class="col">
              <div class="form-check">
                <input
                type="checkbox"
                class="form-check-input"
                id="rememberMe"
                name="rememberMe"
                ngModel
                value="1"
              />
              <label class="form-check-label" for="rememberMe"
                >Remember me</label
              >
              </div>
            </div>
            <div class="col text-right">
              <a [routerLink]="['/forgotten-password']">Forgotten Password</a>
            </div>
          </div>
        </form>
        <!--span
          >Don’t have an account?
          <a [routerLink]="['/register']">Sign up now</a></span
        -->
        <div class="about-login mb-5 mt-5">
          <h3 class="sm-title mb-3">ABOUT SELF-BREATHE</h3>
        <p>
          <strong>Self-Breathe is an online self-management tool for people living with chronic breathlessness.</strong></p>
          <p>The aim of Self-Breathe is to improve the way you manage your breathing and breathlessness via:</p>
          <ol>
            <li>Information about chronic breathlessness and its management.</li>
            <li>The ability to monitor your breathlessness daily.</li>
            <li>Learning techniques specifically aimed to improve your breathing and breathlessness.</li>
            <li>Creating your own breathlessness action plan that can help if you have a breathlessness attack.</li>
            <li>Learning how to exercise safely and recording your workouts to help improve your activity levels and breathlessness.</li>
            <li>Helping set achievable goals to aim towards and complete.</li>
            <li>Answering your programme related questions via an inbuilt messaging service.</li>
          </ol>
          
          
        </div>
        <hr />
      </div>
      
    </div>
    
    <div class="container  inline-footer">
      <div class="row mb-3">
        <div class="col-md-3  offset-md-3">
          © 2021 Self Breathe. All rights reserved.
        </div>
        <div class="col-md-3 text-right">
          <a [routerLink]="['/terms-and-conditions']"
            >Terms &amp; Conditions</a
          >
          |
          <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        </div>
      </div>
    </div>

  </div>
</div>
