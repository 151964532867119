import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoading = false;
  error: string = null;
  id: number;
  hash: string;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.hash = this.route.snapshot.params['hash'];

    // are we logged in?
    let user = this.authService.user.getValue();
    if (user) {
      if (this.authService.redirect) {
        this.router.navigate([this.authService.redirect]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const password = form.value.password;
    const passwordConfirm = form.value.passwordConfirm;
    let authObs: Observable<any>;
    this.isLoading = true;
    authObs = this.authService.resetPassword(this.id, this.hash, password, passwordConfirm);

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        this.router.navigate(['/login']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }
}
