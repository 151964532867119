import { ResourcesService } from './../../resources/resources.service';
import { environment } from './../../../environments/environment';
import { ContentSection } from './../../resources/content-section.model';
import { Subscription } from 'rxjs';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-subnav-techniques',
  templateUrl: './subnav-techniques.component.html',
  styleUrls: ['./subnav-techniques.component.scss'],
})
export class SubnavTechniquesComponent implements OnInit {
  @Output() toggleMenu = new EventEmitter();
  public contentSections: ContentSection[] = [];
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  environment = environment;
  resourceId = 22;
  pages;
  resource;

  constructor(private resourcesService: ResourcesService) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.resourcesService
    .fetchResource(this.resourceId)
    .subscribe((responseData) => {
      this.isFetching = false;
      this.pages = responseData.pages;
      this.resource = responseData.resource;
    });
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }
}
