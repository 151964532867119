
import Swal from 'sweetalert2/src/sweetalert2.js';
import { forkJoin } from 'rxjs';
import { UserSettingsService } from './../services/user-settings.service';
import { ResourcesService } from './../resources/resources.service';
import { WorkoutsService } from './workouts.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Workout } from './workout.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
})
export class ExerciseComponent implements OnInit {
  public days = [];
  showWarning = true;
  public workouts: Workout[] = [];
  public errors: string[];
  isFetching = false;
  resource;
  resourceId = 18;
  areaId = 4;
  guides;
  userSettings;
  showSafetyAdvice = false;
  public today = moment().format('YYYY-MM-DD');
  public monthAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');

  constructor(
    private workoutsService: WorkoutsService,
    private cookieService: CookieService,
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.days.push(
      moment().subtract(4, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
    );
    for (let i = 0; i < 34; i++) {
      this.days.push(moment(this.days[i]).add(1, 'days').format('YYYY-MM-DD'));
    }
    this.isFetching = true;
    forkJoin({
      workouts: this.workoutsService.fetchWorkouts(),
      resource: this.resourcesService.fetchResource(this.resourceId),
      guides: this.resourcesService.fetchAll(this.areaId),
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.workouts = responseData.workouts;
      // filter to the last 30 days only?
      const filteredWorkouts = [];
      this.workouts.forEach((workout, index) => {
        if (
          moment(workout.workout_date) >
          moment().subtract(4, 'weeks').startOf('isoWeek')
        ) {
          filteredWorkouts.push(workout);
        }
      });
      this.workouts = filteredWorkouts;

      this.resource = responseData.resource;
      this.guides = responseData.guides;
      this.userSettings = responseData.userSettings;
      if (
        this.userSettings['exerciseLevel'] &&
        this.userSettings['exerciseSafety']
      ) {
        this.showWarning = false;
        // complete task
        this.userSettingsService
          .saveSetting('getActive', 1)
          .subscribe((_responseData) => {
          });
      }
      this.isFetching = false;
    });
    // get the exercise resources

    this.resourcesService.fetchResource(this.resourceId).subscribe(
      (responseData) => {
        this.isFetching = false;
        this.resource = responseData;
      },
      (error) => {
        this.isFetching = false;
        // this.error = error.message;
      }
    );
  }

  dismissWarning(): void {
    this.showWarning = false;
  }
  onChooseLevel(level): void {
    this.userSettingsService
      .saveSetting('exerciseLevel', level)
      .subscribe((responseData) => {
        this.userSettings['exerciseLevel'] = '' + level;
      });
  }
  onConfirmAdvice() {
    this.userSettingsService
      .saveSetting('exerciseSafety', 1)
      .subscribe((responseData) => {
        this.userSettings['exerciseSafety'] = '1';
        this.showWarning = false;

        window.scrollTo(0, 0);

        // complete task
        this.userSettingsService
          .saveSetting('getActive', 1)
          .subscribe((_responseData) => {
          });

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Thank you for confirming',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      });
  }
}
