<div class="accordion" id="goalsSubnav">
  <div class="card">
    <div
      class="card-header"
      id="linkOverview"
    >
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/goals']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkOverview.isActive"
      >
        Overview
      </button>
    </div>
  </div>
    <div class="card">
      <div
        class="card-header"
        id="headingTwo"
      >
        <button
          class="btn btn-link"
          type="button"
          [routerLink]="['/goals', 'all']"
          routerLinkActive="active"
          #linkTwo="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.collapsed]="!linkTwo.isActive"
        >
        All Goals
        </button>
      </div>
    </div>
    <div class="card">
      <div
        class="card-header"
        id="headingThree"
      >
        <button
          class="btn btn-link"
          type="button"
          [routerLink]="['/goals', 'create']"
          routerLinkActive="active"
          #linkThree="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.collapsed]="!linkThree.isActive"
        >
        Create New Goal
        </button>
      </div>
    </div>
    <div class="card">
        <div
          class="card-header"
          id="headingFour"
        >
          <button
            class="btn btn-link"
            type="button"
            [routerLink]="['/goals', 'about']"
            routerLinkActive="active"
            #linkFour="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
            [class.collapsed]="!linkFour.isActive"
          >
          About Setting Goals
          </button>
        </div>
      </div>
  </div>
  