export class Exercise {
  public exercise_id?: number;
  public name?: string;
  public exercise_type: string;
  public video: string;
  public created?: string;
  public modified?: string;

  constructor(
    exercise_id?: number,
    name?: string,
    exercise_type?: string,
    video?: string,
    created?: string,
    modified?: string
  ) {
    this.exercise_id = exercise_id;
    this.name = name;
    this.exercise_type = exercise_type;
    this.video = video;
    this.created = created;
    this.modified = modified;
  }
}
