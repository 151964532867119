import { ResourcesService } from './resources.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements  OnInit {

  public resources;
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  environment = environment;

  constructor(private resourcesService: ResourcesService) {}

  ngOnInit(): void {

    this.isFetching = true;
    this.resourcesService.fetchAll(1).subscribe(
      responseData => {
        this.isFetching = false;
        this.resources = responseData;
      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

}
