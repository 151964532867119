<app-subnav [title]="'Overview'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container">
    <div class="row">
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg" [routerLink]="['/goals', 'create']">Create New Goal</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="jumbotron text-center jumbotron-slim">
              <div class="status"><span class="icon-wrap text-primary"><fa-icon [icon]="['far', 'circle']"></fa-icon></span> In progress</div>
              <div class="total">{{goals | goalsByStatus : 1}}</div>
          </div>
      </div>
      <div class="col">
          <div class="jumbotron text-center jumbotron-slim">
              <div class="status"><span class="icon-wrap text-primary">
                <fa-icon [icon]="['far', 'check-circle']"></fa-icon></span> Partially</div>
              <div class="total">{{goals | goalsByStatus : 2}}</div>
          </div>
      </div>
      <div class="col">
          <div class="jumbotron text-center jumbotron-slim">
              <div class="status"><span class="icon-wrap text-primary"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span> Achieved</div>
              <div class="total">{{goals | goalsByStatus : 3}}</div>
          </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">

          <h4 class="content-title">
              Latest Goals
            </h4>
            <table class="record-table">
              <tbody>
                <tr *ngFor="let goal of goals" [routerLink]="['/goals','view', goal.goal_id]">
                      <td class="td-date">
                          <span class="num">{{goal.review | date : "d"}}</span>
                          <span class="month">{{goal.review | date : "LLL"}}</span>
                        </td>
                        <td class="text-left">
                            <span class="r-goal">{{goal.goal}}</span>
                        </td>
                        <td class="r-goal-icon">
                            <span class="icon-wrap text-primary">
                              <fa-icon *ngIf="goal.status == 1" [icon]="['far', 'circle']"></fa-icon>
                              <fa-icon *ngIf="goal.status == 2" [icon]="['far', 'check-circle']"></fa-icon>
                              <fa-icon *ngIf="goal.status == 3" [icon]="['fas', 'check-circle']"></fa-icon>
                            </span>
                        </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>
    <div class="row">
      <div class="col col-xs">
          <button class="btn btn-primary btn-block" [routerLink]="['/goals', 'all']">View All Goals</button>
      </div>
      <div class="col col-xs">
          <button class="btn btn-primary btn-block" [routerLink]="['/goals', 'about']"> About Setting Goals</button>
      </div>
    </div>
  </div>