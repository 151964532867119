
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService, AuthResponseData } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isLoading = false;
  error: string = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    // are we logged in?
    let user = this.authService.user.getValue();
    if (user) {
      if (this.authService.redirect) {
        this.router.navigate([this.authService.redirect]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const code = form.value.code;
    const email = form.value.email;
    const password = form.value.password;
    const passwordConfirm = form.value.passwordConfirm;
    const firstName = form.value.firstName;
    const lastName = form.value.lastName;
    let authObs: Observable<AuthResponseData>;

    this.isLoading = true;

    authObs = this.authService.register(code, email, password,  passwordConfirm, firstName, lastName);

    authObs.subscribe(
      (resData) => {
        console.log(resData);
        this.isLoading = false;
        this.router.navigate(['/']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

  }
}
