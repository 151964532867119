import { UserSettingsService } from './../../services/user-settings.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GoalsService } from '../goals.service';

@Component({
  selector: 'app-goals-create',
  templateUrl: './goals-create.component.html',
  styleUrls: ['./goals-create.component.scss'],
})
export class GoalsCreateComponent implements OnInit {
  step = 1;
  @ViewChild('form', { static: false }) goalCreateForm: NgForm;
  submitted = false;
  stepLabels = ['Zero', 'One', 'Two', 'Three', 'Four'];
  error: string;

  constructor(
    private goalsService: GoalsService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    this.goalsService
      .createGoal(
        this.goalCreateForm.value.goal,
        this.goalCreateForm.value.how,
        this.goalCreateForm.value.support,
        this.goalCreateForm.value.review
      )
      .subscribe(
        (responseData) => {
          this.userSettingsService
            .saveSetting('setGoal', 1)
            .subscribe((responeData) => {});
          this.router.navigate(['/goals']);
        },
        (error) => {
          this.error = error.message;
        }
      );
    this.goalCreateForm.reset();
  }
}
