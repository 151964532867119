<app-subnav [title]="'Update Goal'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container">
  
  <div class="row mt-4" *ngIf="goal">
    <div class="col">
      <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="card">
          <div class="card-body">
            <h5>My Goal Is...</h5>
            <textarea
              name="goal"
              id="goal"
              rows="10"
              placeholder="e.g. To walk outdoors down the street within the next the two weeks"
              class="form-control"
              [(ngModel)]="goal.goal"
              required
              maxlength="65535"
              minlength="3"
              #goalInput="ngModel"
            ></textarea>
            <span class="help-block" *ngIf="!goalInput.valid && goalInput.touched">Please enter a goal!</span>
            <p class="small">
              Remember to think SMART and be clear about What and When you want
              to achieve your goal.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5>What I will do towards my goal...</h5>
            <textarea
              name="how"
              id="how"
              rows="10"
              placeholder="e.g. Focus on breathing exercises, pacing techniques and using the hand held fan"
              class="form-control"
              [(ngModel)]="goal.how"
              #how="ngModel"
              required
              maxlength="65535"
              minlength="3"
            ></textarea>
          </div>
        </div>

        <div class="card" >
          <div class="card-body">
            <h5>Who can support me with my goal...</h5>
            <textarea
              name="support"
              id="support"
              rows="10"
              placeholder="e.g. my daughter, my neighbour, SELF-BREATHE"
              class="form-control"
              [(ngModel)]="goal.support"
              #support="ngModel"
              required
              maxlength="65535"
              minlength="3"
            ></textarea>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5>When I will review my goal...</h5>

            <div class="form-group">
              <input
                class="form-control"
                name="review"
                #review="bsDatepicker"
                bsDatepicker
                placeholder="DD/MM/YYY"
                autocomplete="off"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-sb', showWeekNumbers: false  }"
                [(ngModel)]="bsValue"
                required
                >
            </div>


          </div>
        </div>

        <button class="btn btn-primary btn-block mt-2 btn-lg"
        [disabled]="!form.valid"
        >
        Save
        </button>

        <div class="alert alert-danger" *ngIf="error">An error occured : {{error}}</div>
      </form>
    </div>
  </div>
</div>
