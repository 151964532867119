<app-subnav [title]="'My Action Plan'" [section]="'techniques'"></app-subnav>
<div class="relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="container content-wrap mobile-footer-padding content-container bg-white fh"  *ngIf="+step === 0">
  <div>
    <ul class="action-plan-list">
      <li *ngFor="let plan of currentPlan; let i = index;">
          <span class="num">
              {{ i + 1 }}
            </span>
            {{ plan }}</li>
    </ul>
    <button class="btn btn-primary btn-block" (click)="onEdit()">
      Edit action plan
    </button>
  </div>
  </div>

  <div class="container mobile-footer-padding content-container" *ngIf="step > 0">
    <div class="row mt-0 mb-4">
      <div class="col col-xs text-center">
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step === 1, 'text-menu-bg': step != 1 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step === 2, 'text-menu-bg': step != 2 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step === 3, 'text-menu-bg': step != 3 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
      </div>
    </div>

    

    <div *ngIf="+step === 1">
      <h4 class="content-title">1. Select statements</h4>
      <div>
        <div>
          <ul class="exercise-list exercise-list-left">
            <li
              *ngFor="let statement of statements"
              (click)="onSelectStatement(statement)"
              [ngClass]="{
                selected: selectedStatements.indexOf(statement) !== -1
              }"
            >
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'square']"
                  *ngIf="selectedStatements.indexOf(statement) === -1"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  *ngIf="selectedStatements.indexOf(statement) !== -1"
                ></fa-icon>
              </span>
              {{ statement }}
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btn-primary btn-block mt-2" (click)="onNext()">NEXT</button>
    </div>

    <div *ngIf="+step === 2">
      <h4 class="content-title">2. Optional statements</h4>
      <div class="card">
        <div class="card-body">
          <label>WRITE YOUR OWN STATEMENT FOR YOUR PLAN</label>
          <div
            *ngFor="let textarea of textAreasList; let textarea_index = index"
          >
            <textarea
              name="custom{{ textarea }}"
              id="{{ textarea }}"
              rows="3"
              placeholder="e.g. I will go to my happy place"
              class="form-control mb-2"
              ngModel
              required
              maxlength="500"
              minlength="3"
            ></textarea>
          </div>
          <a (click)="onAddTextarea()" class="pointer text-primary"> <span class="icon-wrap text-menu-bg">
            <fa-icon
              [icon]="['fas', 'plus-square']"
              [fixedWidth]="true"
            ></fa-icon>
          </span> Add another statement</a>
        </div>
      </div>
      <button class="btn btn-primary btn-block mt-2" (click)="onNext()">NEXT</button>
    </div>

    <div *ngIf="+step === 3">
      <h4 class="content-title">3. Reorder Statements</h4>
      <div class="card">
        <div class="card-body">
          <p class="text-center">Drag and drop to rearrange</p>
          <ul
            class="sort-list"
            dndDropzone
            dndEffectAllowed="copyMove"
            (dndDrop)="onDrop($event, selectedStatements)"
          >
            <li dndPlaceholderRef class="dndPlaceholder"></li>
            <li
              *ngFor="let item of selectedStatements; let i = index"
              [dndDraggable]="item"
              dndEffectAllowed="move"
              (dndStart)="onDragStart($event)"
              (dndCopied)="onDragged(item, selectedStatements, 'copy')"
              (dndLinked)="onDragged(item, selectedStatements, 'link')"
              (dndMoved)="onDragged(item, selectedStatements, 'move')"
              (dndCanceled)="onDragged(item, selectedStatements, 'none')"
              (dndEnd)="onDragEnd($event)"
            >
              <span class="num">
                {{ i + 1 }}
              </span>
              <span class="sort-item">
               <span class="sort-label">{{ item }}</span> 
                <span class="sort-handle">
                  <span class="icon-wrap">
                    <fa-icon
                      [icon]="['far', 'arrows-alt-v']"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btn-primary btn-block mt-2" (click)="onSave()">
        Finish
      </button>
    </div>

    <div class="row prev-next">
      <div class="col text-left"></div>
      <div class="col text-right"></div>
    </div>
  </div>
</div>
