import { Title } from '@angular/platform-browser';
import { EvaluationsService } from './evaluations/evaluations.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { slideInAnimation, fadeInAnimation } from './route-animations';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation, fadeInAnimation],
})
@HostListener('window:resize', ['$event'])
export class AppComponent implements OnInit {
  title = 'self-breathe-app';
  public innerWidth: number;
  /* pageTitle: Observable<string>;
  showFooterNav: Observable<boolean>;
  showTopNav: Observable<boolean>;

  private defaultShowFooterNav = true;
  private defaultShowTopNav = true;
  private defaultPageTitle = null;*/
  constructor(
    private router: Router,
    private authService: AuthService,
    private evaluationsService: EvaluationsService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    const autoLoginObs = this.authService.autoLogin();

    if (autoLoginObs) {
      autoLoginObs.subscribe(
        (resData) => {
          if (this.router.url.split('?')[0] === '/login') {
            if (this.authService.redirect) {
              this.router.navigate([this.authService.redirect]);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        },
        (errorMessage) => {
          // do something with the error.. console.log(errorMessage);
        }
      );
    }

    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

      // set page title
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join(' | ');
      this.titleService.setTitle(environment.title + ' | ' + title);
    });
  }
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  showMobile(routerOutlet) {
    if (this.innerWidth < 400) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }
  showDesktop(routerOutlet) {
    if (this.innerWidth > 399) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
    }

    if (parent && parent.snapshot.data && parent.snapshot.data.subTitle) {
      data.push(parent.snapshot.data.subTitle);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
