<div class="container mobile-footer-padding content-container" *ngIf="evaluation">
  <div class="row mt-0">
    <div class="col">
      <h4 class="content-title">
        {{ evaluation.date_taken | date: "EEE d MMM" }}
      </h4>

      <div class="jumbotron text-center">
        <span
          class="circle"
          [ngClass]="{
            'bg-success': evaluation.zone == 'g',
            'bg-warning': evaluation.zone == 'a',
            'bg-danger': evaluation.zone == 'r'
          }"
          >&nbsp;</span
        >
        <p class="lead">
          You are in the {{ evaluation.zone | zoneToColour | titlecase }} Zone
        </p>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q1. How is your breathlessness right now?
          </h5>

          <div class="slider-scale">
            <span
              class="circle"
              [ngClass]="{
                'bg-success': evaluation.breathlessness_now < '4',
                'bg-warning':
                  evaluation.breathlessness_now > '3' &&
                  evaluation.breathlessness_now < '7',
                'bg-danger': evaluation.breathlessness_now > '6'
              }"
              [ngStyle]="{
                left: (evaluation.breathlessness_now / 10) * 100 - 10 + '%'
              }"
              >{{ evaluation.breathlessness_now }}</span
            >
            <span class="icon-wrap icon-left"
              ><fa-icon [icon]="['far', 'smile']"></fa-icon
            ></span>
            <span class="scale-line-left"></span
            ><span class="scale-line-right"></span>
            <span class="icon-wrap icon-right"
              ><fa-icon [icon]="['far', 'tired']"></fa-icon
            ></span>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q2. How would you rate your level of breathlessness over the last 24
            hours?
          </h5>

          <div class="slider-scale">
            <span
              class="circle"
              [ngClass]="{
                'bg-success': evaluation.breathlessness_24 < '4',
                'bg-warning':
                  evaluation.breathlessness_24 > '3' &&
                  evaluation.breathlessness_24 < '7',
                'bg-danger': evaluation.breathlessness_24 > '6'
              }"
              [ngStyle]="{
                left: (evaluation.breathlessness_24 / 10) * 100 - 10 + '%'
              }"
              >{{ evaluation.breathlessness_24 }}</span
            >
            <span class="icon-wrap icon-left"
              ><fa-icon [icon]="['far', 'smile']"></fa-icon
            ></span>
            <span class="scale-line-left"></span
            ><span class="scale-line-right"></span>
            <span class="icon-wrap icon-right"
              ><fa-icon [icon]="['far', 'tired']"></fa-icon
            ></span>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q3. Over the last 24 hours, how would you rate your distress due to
            breathlessness?
          </h5>
          <div class="slider-scale">
            <span
              class="circle"
              [ngClass]="{
                'bg-success': evaluation.distress < '4',
                'bg-warning':
                  evaluation.distress > '3' && evaluation.distress < '7',
                'bg-danger': evaluation.distress > '6'
              }"
              [ngStyle]="{ left: (evaluation.distress / 10) * 100 - 10 + '%' }"
              >{{ evaluation.distress }}</span
            >
            <span class="icon-wrap icon-left"
              ><fa-icon [icon]="['far', 'smile']"></fa-icon
            ></span>
            <span class="scale-line-left"></span
            ><span class="scale-line-right"></span>
            <span class="icon-wrap icon-right"
              ><fa-icon [icon]="['far', 'tired']"></fa-icon
            ></span>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q4. How is your overall health today?
          </h5>
          <div class="slider-scale">
            <span
              class="circle"
              [ngClass]="{
                'bg-danger': evaluation.health < '40',
                'bg-warning':
                  evaluation.health > '39' && evaluation.health < '70',
                'bg-success': evaluation.health > '69'
              }"
              [ngStyle]="{ left: (evaluation.health / 100) * 100 - 10 + '%' }"
              >{{ evaluation.health }}</span
            >
            <span class="icon-wrap icon-left"
              ><fa-icon [icon]="['far', 'tired']"></fa-icon
            ></span>
            <span class="scale-line-left"></span
            ><span class="scale-line-right"></span>
            <span class="icon-wrap icon-right"
              ><fa-icon [icon]="['far', 'smile']"></fa-icon
            ></span>
          </div>
        </div>
      </div>
      <div class="card mt-3" *ngIf="evaluation.today">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q5. Which of these statements best describes how you feel today?
          </h5>
          <p *ngIf="evaluation.today ==='1'">I am feeling at my best</p>
          <p *ngIf="evaluation.today ==='2'">I feel my breathing and breathlessness is getting worse</p>
          <p *ngIf="evaluation.today ==='3'">I feel my breathing and breathlessness is much worse</p>
         
        </div>
      </div>

      <div class="card mt-3" *ngIf="evaluation.since">
        <div class="card-body">
          <h5 class="card-title card-title-lc">
            Q6. Since your last log on to SELF-BREATHE, which best describes your activity levels?
          </h5>
          <p *ngIf="evaluation.since ==='1'">About the same</p>
          <p *ngIf="evaluation.since ==='2'">Slightly higher</p>
          <p *ngIf="evaluation.since ==='3'">A lot higher</p>
          <p *ngIf="evaluation.since ==='4'">Slightly less</p>
          <p *ngIf="evaluation.since ==='5'">A lot less</p>
        </div>
      </div>

      <button class="btn btn-white btn-block mt-4" (click)="onDelete()">
        <span class="icon-wrap text-danger"
          ><fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true"></fa-icon>

          Delete Evaluation
        </span>
      </button>
    </div>
  </div>
</div>
