
<div class="container content-wrap relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="row mt-3">
    
    <div class="col-6 col-xs-6 col-md-4 col-lg-4 mb-4" *ngFor="let resource of resources">
      <a class="resource-nav-item" [routerLink]="['/resources', resource.content_section_id]">
        <div class="resource-index-images rounded drop-shadow">
          <img
            src="{{resource.image_filename | getThumbnail : 'medium-landscape'}}"
            alt=""
            class="img-fluid"
            *ngIf="resource.image_filename"
          />
      </div>
        <span class="nav-item-title"
          >
          {{resource.title}}
          <span class="icon-wrap"
            ><fa-icon
              [icon]="['far', 'arrow-circle-right']"
              [fixedWidth]="true"
            ></fa-icon></span
        ></span>
      </a>
    </div>


  </div>
</div>
