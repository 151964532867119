<app-subnav [title]="'My Goal'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container">
  <div class="row">
    <div class="col">
      <h4 class="content-title">
        Update Status
      </h4>
      <div class="row" *ngIf="goal">
        <div class="col">
          <div class="jumbotron text-center jumbotron-slim pointer" [ngClass]="{'active': goal.status==1, 'muted': goal.status!=1}" tabindex="0" (click)="onSetGoalStatus(1)">
            <div class="status-icon">
              <span class="icon-wrap" [ngClass]="{'text-primary': goal.status==1}">
                <fa-icon [icon]="['far', 'circle']"></fa-icon>
              </span>
            </div>
            <div class="status">In progress</div>
          </div>
        </div>
        <div class="col">
          <div class="jumbotron text-center jumbotron-slim pointer" [ngClass]="{'active': goal.status==2, 'muted': goal.status!=2}" tabindex="0" (click)="onSetGoalStatus(2)">
            <div class="status-icon">
              <span class="icon-wrap" [ngClass]="{'text-primary': goal.status==2}"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
            </div>
            <div class="status">Partially</div>
          </div>
        </div>
        <div class="col">
          <div class="jumbotron text-center jumbotron-slim pointer" [ngClass]="{'active': goal.status==3, 'muted': goal.status!=3}" tabindex="0" (click)="onSetGoalStatus(3)">
            <div class="status-icon">
              <span class="icon-wrap" [ngClass]="{'text-primary': goal.status==3}"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
            </div>
            <div class="status">Achieved</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col" *ngIf="goal">
      <h4 class="content-title">
        Review on {{ goal.review | date: "EEE d LLL" }}
      </h4>

      <div class="card">
        <div class="card-body">
          <h6>MY GOAL IS…</h6>
          <p>{{ goal.goal }}</p>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-body">
          <h6>WHAT I WILL DO towards my goals…</h6>
          <p>{{ goal.how }}</p>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-body">
          <h6>Who can support me with my goal….</h6>
          <p>{{ goal.support }}</p>
        </div>
      </div>



 <div class="text-center mt-5">
        
  <button class="btn btn-primary btn-block" [routerLink]="['/goals', 'update', goal.goal_id]">
    Edit Goal
  </button>

  <button class="btn btn-white btn-block" (click)="onDelete()">
    <span class="icon-wrap text-danger"><fa-icon
        [icon]="['far', 'trash-alt']"
        [fixedWidth]="true"
      ></fa-icon>

    Delete Goal
    
      
    </span>
  </button>
  </div>
    </div>
  </div>
</div>


