<app-subnav [title]="resource?.title" [section]="'exercise'"></app-subnav>
<div class="container mobile-footer-padding relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  
 
    <div  *ngFor="let resource of resources">

      <ng-container *ngIf="(resourceId && +resource.content_section_id === +resourceId) || !resourceId">
      <div class="img-container">
        <img
          [src]="resource?.image_filename | getThumbnail: 'wide'"
          alt="Image showing {{ resource?.title }}"
          class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none"
          *ngIf="resource?.image_filename"
        />
        <img
          [src]="resource?.image_filename | getThumbnail: 'wide'"
          alt="Image showing {{ resource?.title }}"
          class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block drop-shadow rounded"
          *ngIf="resource?.image_filename"
        />
      </div>

        <ul class="row content-list mt-3">
          <li *ngFor="let page of resource.pages" class="col-md-6">
              <a [routerLink]="[
              '/exercise',
              'guides',
              page.content_section_id,
              page.content_page_id
            ]">
              <img 
              src="{{page.image_filename | getThumbnail : 'medium-landscape'}}"
              alt=""
              class=""
              *ngIf="page.image_filename"
              height="55"
              />
              <img 
              src="https://via.placeholder.com/90x55"
              alt=""
              class="img-fluid"
              *ngIf="!page.image_filename"
              />
              <span class="content-label">{{page.title | uppercase}}</span>
              <span class="icon-wrap">
                <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
              </span>
            </a></li>
      </ul>
</ng-container>
</div>
