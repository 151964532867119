<div class="container-fluid mobile-footer-padding content-container bg-dark fh login">
  <div class="login-logo">
    <img src="assets/img/logo-white.svg" />
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="text-center">
        <h2 class="text-white">Forgotten Password</h2>
      </div>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!isLoading && !sent"
      >
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon [icon]="['fal', 'user']" [fixedWidth]="true"></fa-icon
              ></span>
            </div>
            <input
              type="email"
              id="email"
              class="form-control"
              ngModel
              name="email"
              placeholder="Email"
              required
              email
            />
          </div>

          <!--label for="email">Email address</label-->
        </div>

        <button
          type="submit"
          [disabled]="!authForm.valid"
          class="btn btn-primary btn-block btn-lg"
        >
          Submit
        </button>
        <hr />
      </form>
      <p *ngIf="sent">
        If your email exists you will have been sent a link to reset your
        password.
      </p>
      <hr />
      <span
        >Don’t have an account?
        <a [routerLink]="['/register']">Sign up now</a></span
      >
    </div>
  </div>
</div>
