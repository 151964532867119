import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { Message } from './message.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  error = new Subject<string>();

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  fetchMessages(category?: string) {
    let searchParams = new HttpParams();
    if (category) {
      searchParams = searchParams.append('category', category.toString());
    }
    return this.http
      .get<any>(environment.apiUrl + '/messages', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const messagesArray: Message[] = [];
          responseData['_embedded'].messages.forEach((message) => {
            messagesArray.push(message);
          });
          return messagesArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createMessage(
    message,
    category
  ) {
    const messagesData = {
      message,
      category
    };
    return this.http.post<Message>(
      environment.apiUrl + '/messages',
      messagesData,
      {
        observe: 'response',
      }
    );
  }
}
