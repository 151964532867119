<div class="accordion" id="exerciseSubnav" *ngIf="userSettings">
  <div class="card">
    <div class="card-header" id="linkOverview">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/exercise']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkOverview.isActive"
      >
        Overview
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/exercise', 'workouts']"
        routerLinkActive="active"
        #linkTwo="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkTwo.isActive"
      >
        All Workouts
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFour">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/exercise', 'add']"
        routerLinkActive="active"
        #linkFour="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkFour.isActive"
      >
        Add Entry to Workouts
      </button>
    </div>
  </div>
  <div
    class="card"
    *ngIf="userSettings?.exerciseLevel && userSettings?.exerciseLevel === '1'"
  >
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link collapsed"
          type="button"
          [routerLink]="['/exercise', 'guides', 19]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          #linkBed="routerLinkActive"
          [class.collapsed]="!linkBed.isActive"
          (click)="onToggleMenu()"
        >
          Bed Based Exercise Guides
        </button>
      </h2>
    </div>
  </div>
  <div class="card" *ngIf="userSettings?.exerciseLevel">
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link collapsed"
          type="button"
          [routerLink]="['/exercise', 'guides', 20]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          #linkSitting="routerLinkActive"
          [class.collapsed]="!linkSitting.isActive"
          (click)="onToggleMenu()"
        >
          Sitting Exercise Guides
        </button>
      </h2>
    </div>
  </div>
  <div
    class="card"
    *ngIf="userSettings?.exerciseLevel && userSettings?.exerciseLevel === '2'"
  >
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link collapsed"
          type="button"
          [routerLink]="['/exercise', 'guides', 21]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          #linkStanding="routerLinkActive"
          [class.collapsed]="!linkStanding.isActive"
          (click)="onToggleMenu()"
        >
          Standing Exercise Guides
        </button>
      </h2>
    </div>
  </div>
  <!--div
      id="collapseThree"
      class="show"
      aria-labelledby="headingThree"
      data-parent="#accordionExample"
    >
      <div class="card-body">
       
        <div class="col-md-4 col-12 mt-3" *ngFor="let resource of guides">
          <h5 class="card-title text-white">{{ resource.title }}</h5>
          <ul class="row content-list">
            <li *ngFor="let page of resource.pages" class="col-md-12">
                <a [routerLink]="[
                '/exercise',
                'guides',
                page.content_section_id,
                page.content_page_id
              ]">
                <img 
                src="{{page.image_filename | getThumbnail : 'medium-landscape'}}"
                alt=""
                class="img-fluid"
                *ngIf="page.image_filename"
                />
                <img 
                src="https://via.placeholder.com/90x55"
                alt=""
                class="img-fluid"
                *ngIf="!page.image_filename"
                />
                <span class="content-label">{{page.title | uppercase}}</span>
                <span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
                </span>
              </a></li>
        </ul>
      </div>

      </div>
    </div>
  </div-->

  <div class="card">
    <div class="card-header" id="headingFour">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/exercise', 'more-information']"
        routerLinkActive="active"
        #linkMore="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkMore.isActive"
      >
        More Information
      </button>
    </div>
  </div>
</div>
