import { forkJoin } from 'rxjs';
import { UserSettingsService } from './../../services/user-settings.service';
import { ResourcesService } from './../../resources/resources.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-subnav-exercise',
  templateUrl: './subnav-exercise.component.html',
  styleUrls: ['./subnav-exercise.component.scss'],
})
export class SubnavExerciseComponent implements OnInit {

  @Output() toggleMenu = new EventEmitter();
  isFetching = false;
  resourceId = 18;
  areaId = 4;
  resource;
  guides;
  userSettings;

  constructor(
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    // get the exercise resources
    this.isFetching = true;
    forkJoin({
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.userSettings = responseData.userSettings;
      this.isFetching = false;
    });
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }
}
