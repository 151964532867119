
<app-subnav [title]="'Change your password'"></app-subnav>
<div class="container-fluid mobile-footer-padding content-container fh ">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
      class="goal-form"
      #authForm="ngForm"
      (ngSubmit)="onSubmit(authForm)"
        *ngIf="!isLoading && !changed"
      >
        <div class="form-group">
          <label for="currentPassword">Current Password</label>
         
            <input
              type="password"
              id="currentPassword"
              class="form-control"
              ngModel
              name="currentPassword"
              required
              minlength="6"
              placeholder="Your Current Password"
              #currentPassword="ngModel"
            />
        </div>

        <div class="form-group">

          <label for="password">New Password</label>
          
            <input
              type="password"
              id="password"
              class="form-control"
              ngModel
              name="password"
              required
              minlength="6"
              placeholder="New Password"
              #password="ngModel"
            />
          <p class="instruction">
            Minimum ten characters, at least one uppercase letter, one lowercase
            letter, one number and one special character ($@!%*?&)
          </p>
        </div>
        <div class="form-group">
          <label for="inputPassword">Confirm New Password</label>
          
            <input
              type="password"
              id="passwordConfirm"
              class="form-control"
              ngModel
              name="passwordConfirm"
              required
              minlength="6"
              placeholder="Confirm New Password"
              #passwordConfirm="ngModel"
            />
        </div>

        <button
          type="submit"
          [disabled]="!authForm.valid"
          class="btn btn-primary btn-block"
        >
          Submit
        </button>
      </form>

      <p *ngIf="changed">
        Your password has been changed.
        <a [routerLink]="['/dashboard']">Back to dashboard</a>
      </p>
    </div>
  </div>
</div>
