import { Title } from '@angular/platform-browser';
import { ResourcesService } from './../../resources/resources.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.scss']
})
export class ExerciseInfoComponent implements OnInit {
  pages;
  resourceId = 18;
  resource;
  isFetching = false;
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
      this.resourcesService
        .fetchResource(this.resourceId)
        .subscribe((responseData) => {
          this.isFetching = false;
          this.pages = responseData.pages;
          this.resource = responseData.resource;
          // set custom HTML title
          this.titleService.setTitle(environment.title + ' | Exercise | ' + this.resource.title);
        });
  }
}
