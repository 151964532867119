<nav
      class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
    >
      <div class="d-flex justify-content-center" style="margin: 0 auto;">
        <ul class="navbar-nav">
          <li class="nav-item"
          routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/dashboard']">
                <span class="icon-wrap"><fa-icon [icon]="['fas', 'home']"></fa-icon></span>
                <span class="nav-label">Home</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/evaluations']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon></span>
              <span class="nav-label">Evaluate</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/goals']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon></span>
              <span class="nav-label">Goals</span>
            </a>
          </li>
          <li class="nav-item"
          routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/techniques']">
                <span class="icon-wrap"><fa-icon [icon]="['fas', 'lungs']"></fa-icon></span>
                <span class="nav-label">Techniques</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/exercise']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'walking']"></fa-icon></span>
              <span class="nav-label">Exercise</span>
            </a>
          </li>
          
        </ul>
      </div>
    </nav>
