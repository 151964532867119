import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subnav-goals',
  templateUrl: './subnav-goals.component.html',
  styleUrls: ['./subnav-goals.component.scss']
})
export class SubnavGoalsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
