
<app-subnav [title]="resource?.pages[0].title" *ngIf="resource"></app-subnav>


<div class="container content-wrap mobile-footer-padding relative text-page-container">
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
    <div class="row" *ngIf="resource">
      <div class="col">
       
      </div>
        <div class="container  container-resource-page">
          <div *ngIf="resource?.pages[0].page_type_id === '2'">
            <video width="100%" poster="{{resource.pages[0].content.posterUrl}}" controls preload class="fade-in rounded drop-shadow">
              <source src="{{resource.pages[0].content.videoUrl}}" type="video/mp4">
              <track src="{{resource.pages[0].content.captionsUrl}}" kind="subtitles" srclang="en" label="English" default *ngIf="resource.pages[0].content.captionsUrl">
             </video>
          </div>
          <div >
            <div [innerHTML]="resource?.pages[0].content | safeHtml" *ngIf="resource?.pages[0].page_type_id === '1'"></div>
            <div *ngIf="resource?.pages[0].page_type_id === '2'">
              <div [innerHTML]="resource?.pages[0].content.content | safeHtml"></div>
            </div>
          </div>
          
          <button class="btn btn-primary mt-3" [routerLink]="['/dashboard']"> Continue </button>
  
      </div>
    </div>
  </div>
  