import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'zoneToColour'
})
export class ZoneToColour implements PipeTransform{
    transform(colourChar: string){
        const colours = {
          r : 'red',
          a : 'amber',
          g : 'green'
        };

        return colours[colourChar];
    }
}
