import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from './../../resources/resources.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exercise-how-to',
  templateUrl: './exercise-how-to.component.html',
  styleUrls: ['./exercise-how-to.component.scss']
})
export class ExerciseHowToComponent implements OnInit {

  areaId = 4;
  isFetching = false;
  resources;
  public resourceId;
  resource;

  constructor(private resourcesService: ResourcesService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.resourceId = this.route.snapshot.params.resourceId;
      this.resourcesService
      .fetchResource(this.resourceId)
      .subscribe((responseData) => {
        this.isFetching = false;
        this.resource = responseData.resource;
      });

    });

    this.isFetching = true;
    this.resourcesService
    .fetchAll(this.areaId)
    .subscribe((responseData) => {
      this.isFetching = false;
      this.resources = responseData;
    });

  }

}
