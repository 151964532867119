<div class="container-fluid content-container bg-dark fh login">
    <div class="login-logo">
        <img src="assets/img/logo-white.svg">
    </div>

    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="text-center"><h2 class="text-white">Reset Password</h2></div>
        <div class="alert alert-danger" *ngIf="error">
          <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading" style="text-align: center;">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
          
            <div class="form-group">
                <div class="input-group" [ngClass]="(password.dirty && password.invalid) ? 'is-invalid' : ''">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><fa-icon [icon]="['fal', 'key']" [fixedWidth]="true"></fa-icon
                    ></span>
                  </div>
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    ngModel
                    name="password"
                    required
                    minlength="6"
                    placeholder="Password"
                    #password="ngModel"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&\#])[A-Za-z\d$@$!%*?&\#]{10,}$"
                  />
                </div>
                <p>Minimum ten characters, at least one uppercase letter, one lowercase letter, one number and one special character ($@!%*?&)</p>
                <!--label for="inputPassword">Password</label-->
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><fa-icon [icon]="['fal', 'key']" [fixedWidth]="true"></fa-icon
                    ></span>
                  </div>
                  <input
                    type="password"
                    id="passwordconfirm"
                    class="form-control"
                    ngModel
                    name="passwordConfirm"
                    required
                    minlength="6"
                    placeholder="Confirm Password"
                  />
                </div>
                <!--label for="inputPassword">Password</label-->
              </div>
          
          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary btn-block btn-lg"

            >Submit</button>
          <hr>
        </form>
        <hr>
        <span>Don’t have an account? <a [routerLink]="['/register']">Sign up now</a></span>
      </div>
    </div>
</div>
