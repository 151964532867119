import { UserSettingsService } from './../services/user-settings.service';
import { forkJoin } from 'rxjs';
import { Workout } from './../exercise/workout.model';
import { WorkoutsService } from './../exercise/workouts.service';
import { AuthService } from './../auth/auth.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { GoalsService } from '../goals/goals.service';
import { Goal } from './../goals/goal.model';
import { EvaluationsService } from './../evaluations/evaluations.service';
import { Evaluation } from './../evaluations/evaluation.model';
import { Router } from '@angular/router';
import { SwipeService } from '../shared/swipe.service';
import { SwiperDirective, SwiperComponent } from 'ngx-swiper-wrapper';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public goals: Goal[] = [];
  public goalsToReview = [];
  public evaluations: Evaluation[] = [];
  public evlauationToday = false;
  public latestEvaluation: Evaluation = null;
  public workouts: Workout[] = [];
  public latestWorkout: Workout;
  public userName: string;
  public errors = [];
  isFetching = false;
  sliderIndex = 0;
  swiperConfig = { effect: 'slide', threshold: 10 };
  userSettings;
  daysSinceEvaluation = -1;
  daysSinceWorkout = -1;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  constructor(
    private goalsService: GoalsService,
    private evaluationsService: EvaluationsService,
    private authService: AuthService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private swipeService: SwipeService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userName = user.first_name;
      }
    });

    this.isFetching = true;

    forkJoin({
      goals: this.goalsService.fetchGoals(),
      evaluations: this.evaluationsService.fetchEvaluations(),
      evaluatedToday: this.evaluationsService.checkToday(),
      workouts: this.workoutsService.fetchWorkouts(),
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.goals = responseData.goals;

      this.goals.forEach((goal) => {
        if (
          moment(goal.review) <= moment().add(1, 'w').startOf('day') &&
          +goal.status !== 3
        ) {
          this.goalsToReview.push(goal);
        }
      });

      this.evaluations = responseData.evaluations;
      if (this.evaluations.length > 0) {
        this.latestEvaluation = this.evaluations[this.evaluations.length - 1];
      }
      if (responseData.evaluatedToday.length > 0) {
        this.evlauationToday = true;
      }

      if (this.latestEvaluation) {
        let b = moment(this.latestEvaluation.date_taken);
        let a = moment();
        this.daysSinceEvaluation = a.diff(b, 'days');
      }
      this.workouts = responseData.workouts;
      if (this.workouts.length > 0) {
        this.latestWorkout = this.workouts[this.workouts.length - 1];

        let b = moment(this.latestWorkout.workout_date);
        let a = moment();
        this.daysSinceWorkout = a.diff(b, 'days');
      }

      this.userSettings = responseData.userSettings;
      if (this.userSettings['watchIntro']) {
        this.sliderIndex = 1;
        if (this.userSettings['takeEvaluation']) {
          this.sliderIndex = 2;
          if (this.userSettings['viewTechniques']) {
            this.sliderIndex = 3;
            if (this.userSettings['setupActionPlan']) {
              this.sliderIndex = 4;
              if (this.userSettings['getActive']) {
                this.sliderIndex = 5;
                if (this.userSettings['aboutGoal']) {
                  this.sliderIndex = 6;
                }
              }
            }
          }
        }
      }
      this.isFetching = false;
    });
  }
  onNextTask() {
    this.componentRef.directiveRef.nextSlide();
  }
  onPrevTask() {
    this.componentRef.directiveRef.prevSlide();
  }
  onSetIndex(index) {
    this.sliderIndex = index;
  }
}
