import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  // error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchAll() {
    return this.http
      .get<any>(environment.apiUrl + '/user_settings', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          if(responseData['_embedded'].user_settings[0]){
            return responseData['_embedded'].user_settings[0];
          }
          else{
            return null;
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveSetting(
    settingVar,
    settingVal
  ) {
    const settingData = {
      settingVar,
      settingVal
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_settings',
      settingData,
      {
        observe: 'response',
      }
    );
  }


}
