<app-subnav [title]="'All Goals'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container">
      
    <div class="row">
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg" [routerLink]="['/goals', 'create']">Create New Goal</button>
      </div>
    </div>
    
    <div class="row mt-3">
      <div class="col" *ngIf="goals">

          <h4 class="content-title">
              {{goals.length}} Goals
            </h4>
            <div class="goal-filter">
                Filter: 
                <span class="status" [ngClass]="{'active': filtered[1]}" (click)="filter(1)"><span class="icon-wrap text-primary"><fa-icon [icon]="['far', 'circle']"></fa-icon></span> In progress</span>
                <span class="status" [ngClass]="{'active': filtered[2]}" (click)="filter(2)"><span class="icon-wrap text-primary"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span> Partially</span>
                <span class="status" [ngClass]="{'active': filtered[3]}" (click)="filter(3)"><span class="icon-wrap text-primary"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span> Achieved</span>
            </div>
            <table class="record-table">
                <tbody>
                    <tr *ngFor="let goal of filteredGoals(goals)" [routerLink]="['/goals','view', goal.goal_id]">
                          <td class="td-date">
                              <span class="num">{{goal.review | date : "d"}}</span>
                              <span class="month">{{goal.review | date : "LLL"}}</span>
                            </td>
                            <td class="text-left">
                                <span class="r-goal">{{goal.goal}}</span>
                            </td>
                            <td class="r-goal-icon">
                                <span class="icon-wrap text-primary">
                                  <fa-icon *ngIf="goal.status == 1" [icon]="['far', 'circle']"></fa-icon>
                                  <fa-icon *ngIf="goal.status == 2" [icon]="['far', 'check-circle']"></fa-icon>
                                  <fa-icon *ngIf="goal.status == 3" [icon]="['fas', 'check-circle']"></fa-icon>
                                </span>
                            </td>
                      </tr>
                  </tbody>
          </table>

      </div>
    </div>
  </div>