<app-subnav [title]="'Create New Goal'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container">
  <div class="row mt-0">
    <div class="col col-xs text-center">
      <span class="icon-wrap mr-1" [ngClass]="{'text-primary': step==1, 'text-menu-bg': step!=1}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
      <span class="icon-wrap mr-1" [ngClass]="{'text-primary': step==2, 'text-menu-bg': step!=2}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
      <span class="icon-wrap mr-1" [ngClass]="{'text-primary': step==3, 'text-menu-bg': step!=3}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
      <span class="icon-wrap mr-1" [ngClass]="{'text-primary': step==4, 'text-menu-bg': step!=4}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <h4 class="content-title">
        Step {{stepLabels[step]}}
      </h4>
      <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="card" [hidden]="step!=1">
          <div class="card-body">
            <h5>My Goal Is...</h5>
            <textarea
              name="goal"
              id="goal"
              rows="10"
              placeholder="e.g. To walk outdoors down the street within the next the two weeks"
              class="form-control"
              ngModel
              required
              maxlength="65535"
              minlength="3"
              #goal="ngModel"
            ></textarea>
            <span class="help-block" *ngIf="!goal.valid && goal.touched">Please enter a goal!</span>
            <p class="small">
              Remember to think SMART and be clear about What and When you want
              to achieve your goal.
            </p>
          </div>
        </div>

        <div class="card" [hidden]="step!=2">
          <div class="card-body">
            <h5>What I will do towards my goal...</h5>
            <textarea
              name="how"
              id="how"
              rows="10"
              placeholder="e.g. Focus on breathing exercises, pacing techniques and using the hand held fan"
              class="form-control"
              ngModel
              #how="ngModel"
              required
              maxlength="65535"
              minlength="3"
            ></textarea>
          </div>
        </div>

        <div class="card" [hidden]="step!=3">
          <div class="card-body">
            <h5>Who can support me with my goal...</h5>
            <textarea
              name="support"
              id="support"
              rows="10"
              placeholder="e.g. my daughter, my neighbour, SELF-BREATHE"
              class="form-control"
              ngModel
              #support="ngModel"
              required
              maxlength="65535"
              minlength="3"
            ></textarea>
          </div>
        </div>

        <div class="card" [hidden]="step!=4">
          <div class="card-body">
            <h5>When I will review my goal...</h5>

            <div class="form-group">
              <input
                class="form-control"
                name="review"
                #review="bsDatepicker"
                bsDatepicker
                placeholder="DD/MM/YYY"
                autocomplete="off"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-sb', showWeekNumbers: false  }"
                ngModel
                required
                >
            </div>


          </div>
        </div>

        <button class="btn btn-primary btn-block mt-2 btn-lg"
        *ngIf="step < 4"
        [disabled]="(step==1 && !goal.valid) || (step==2 && !how.valid) || (step==3 && !support.valid)  || (step==4 && !form.valid)"
        type="button"
        (click)="step = step+1"
        >
        Next
        </button>
        <button class="btn btn-primary btn-block mt-2 btn-lg"
        *ngIf="step == 4"
        [disabled]="!form.valid"
        >
        Save
        </button>

        <div class="alert alert-danger" *ngIf="error">An error occured : {{error}}</div>
      </form>
    </div>
  </div>
</div>
