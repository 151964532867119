import { UserSettingsService } from './../services/user-settings.service';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from './../resources/resources.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-techniques',
  templateUrl: './techniques.component.html',
  styleUrls: ['./techniques.component.scss'],
})
export class TechniquesComponent implements OnInit, OnDestroy {
  public resource;
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  environment = environment;
  pages;
  resourceId = 22;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
      this.isFetching = true;
      this.resourcesService
        .fetchResource(this.resourceId)
        .subscribe((responseData) => {
          this.isFetching = false;
          this.pages = responseData.pages;
          this.resource = responseData.resource;
          // set custom HTML title
          this.titleService.setTitle(
            environment.title + ' | Techniques | ' + this.resource.title
          );
        });
  }

  ngOnDestroy(): void{
    this.userSettingsService
              .saveSetting('viewTechniques', 1)
              .subscribe((responeData) => {});
  }

}
