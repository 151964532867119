export class Evaluation {
  public evaluation_id?: number;
  public userId?: number;
  public date_taken: string;
  public breathlessness_now: number;
  public breathlessness_24: number;
  public distress: number;
  public health: number;
  public today: number;
  public since: number;
  public zone: string;
  public created?: string;
  public modified?: string;

  constructor(
    evaluation_id?: number,
    userId?: number,
    date_taken?: string,
    breathlessness_now?: number,
    breathlessness_24?: number,
    distress?: number,
    health?: number,
    today?:number,
    since?:number,
    zone?: string,
    created?: string,
    modified?: string
  ) {
    this.evaluation_id = evaluation_id;
    this.userId = userId;
    this.date_taken = date_taken;
    this.breathlessness_now = breathlessness_now;
    this.breathlessness_24 = breathlessness_24;
    this.distress = distress;
    this.health = health;
    this.today = today;
    this.since = since;
    this.zone = zone;
    this.created = created;
    this.modified = modified;
  }
}
