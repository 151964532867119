import { UserSettingsService } from './../../services/user-settings.service';
import { Component, OnInit } from '@angular/core';
import {
  Options as ngSliderOptions,
  ChangeContext,
} from '@angular-slider/ngx-slider';

import { EvaluationsService } from '../evaluations.service';
import { Router } from '@angular/router';
import { Evaluation } from '../evaluation.model';

@Component({
  selector: 'app-evaluate-today',
  templateUrl: './evaluate-today.component.html',
  styleUrls: ['./evaluate-today.component.scss'],
})
export class EvaluateTodayComponent implements OnInit {
  step = 1;
  changed = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  };
  q1Value: number = 5;
  q1Options: ngSliderOptions = {
    floor: 0,
    ceil: 10,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
  };

  q2Value: number = 5;
  q2Options: ngSliderOptions = {
    floor: 0,
    ceil: 10,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
  };

  q3Value: number = 5;
  q3Options: ngSliderOptions = {
    floor: 0,
    ceil: 10,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
  };

  q4Value: number = 50;
  q4Options: ngSliderOptions = {
    floor: 0,
    ceil: 100,
    vertical: true,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
  };

  q5Value;
  q6Value;

  error: string;

  evaluation: Evaluation;
  evaluations;
  allEvaluations;
  zone: string;

  constructor(
    private evaluationsService: EvaluationsService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {}

  onUserChangeStart(qNum: number, changeContext: ChangeContext): void {
    this.changed[qNum] = true;
  }

  ngOnInit(): void {
    // do we need to do an evaluation?

    this.evaluationsService.fetchEvaluations().subscribe((evaluationData) => {
      this.allEvaluations = evaluationData;
    });

    this.evaluationsService.checkToday().subscribe((evaluationData) => {
      this.evaluations = evaluationData;
      if (evaluationData.length > 0) {
        this.evaluation = evaluationData[0];
        this.q1Value = this.evaluation.breathlessness_now;
        this.q2Value = this.evaluation.breathlessness_24;
        this.q3Value = this.evaluation.distress;
        this.q4Value = this.evaluation.health;
        this.q5Value = +this.evaluation.today;
        this.q6Value = +this.evaluation.since;
      }
    });
  }

  next() {
    if (this.step === 5 && this.allEvaluations.length < 1) {
      this.step++;
    }
    this.step++;
    if (this.step === 7) {
      // save it
      if (this.evaluation) {
        this.evaluation.breathlessness_now = this.q1Value;
        this.evaluation.breathlessness_24 = this.q2Value;
        this.evaluation.distress = this.q3Value;
        this.evaluation.health = this.q4Value;
        this.evaluation.today = this.q5Value;
        this.evaluation.since = this.q6Value;

        // work out the zone

        // do they have a previous record?

        let total = 0;
        total =
          this.evaluation.breathlessness_now * 10 +
          this.evaluation.breathlessness_24 * 10 +
          this.evaluation.distress * 10 +
          (100 - this.evaluation.health);
        const avg = total / 4;

        // do they have a previous record?
        let previousEvaluation = null;
        if (this.allEvaluations.length > 0) {
          previousEvaluation = this.allEvaluations[
            this.allEvaluations.length - 2
          ];
        }

        let zone = '';
        if (previousEvaluation) {
          // compare

          if (+this.q5Value > +previousEvaluation.today) {
            if (+this.q5Value === 2) {
              zone = 'a';
            } else if (+this.q5Value === 3) {
              zone = 'r';
            }
          } else if (+this.q5Value < +previousEvaluation.today) {
            if (+this.q5Value === 2) {
              zone = 'a';
            } else if (+this.q5Value === 1) {
              zone = 'g';
            }
          } else {
            // keep it the same?
            zone = previousEvaluation.zone;
          }
        } else {
          if (this.q5Value === 1) {
            zone = 'g';
          }
          if (this.q5Value === 2) {
            zone = 'a';
          }
          if (this.q5Value === 3) {
            zone = 'r';
          }
        }

        this.evaluation.zone = zone;
        this.zone = zone;

        this.evaluationsService.updateEvaluation(this.evaluation).subscribe(
          (responseData) => {
            this.userSettingsService
              .saveSetting('takeEvaluation', 1)
              .subscribe((responeData) => {});
          },
          (error) => {
            this.error = error.message;
          }
        );
      } else {
        const breathlessness_now = this.q1Value;
        const breathlessness_24 = this.q2Value;
        const distress = this.q3Value;
        const health = this.q4Value;
        const today = this.q5Value;
        const since = this.q6Value;

        // work out the zone
        let total = 0;
        total =
          breathlessness_now * 10 +
          breathlessness_24 * 10 +
          distress * 10 +
          (100 - health);
        const avg = total / 4;
        // do they have a previous record?
        let previousEvaluation = null;
        if (this.allEvaluations.length > 0) {
          previousEvaluation = this.allEvaluations[
            this.allEvaluations.length - 1
          ];
        }

        let zone = '';
        if (previousEvaluation) {
          // compare

          if (+this.q5Value > +previousEvaluation.today) {
            if (+this.q5Value === 2) {
              zone = 'a';
            } else if (+this.q5Value === 3) {
              zone = 'r';
            }
          } else if (+this.q5Value < +previousEvaluation.today) {
            if (+this.q5Value === 2) {
              zone = 'a';
            } else if (+this.q5Value === 1) {
              zone = 'g';
            }
          } else {
            // keep it the same?
            zone = previousEvaluation.zone;
          }
        } else {
          if (this.q5Value === 1) {
            zone = 'g';
          }
          if (this.q5Value === 2) {
            zone = 'a';
          }
          if (this.q5Value === 3) {
            zone = 'r';
          }
        }

        this.zone = zone;

        this.evaluationsService
          .createEvaluation(
            breathlessness_now,
            breathlessness_24,
            distress,
            health,
            today,
            since,
            zone
          )
          .subscribe(
            (responseData) => {
              this.userSettingsService
                .saveSetting('takeEvaluation', 1)
                .subscribe((responeData) => {});
            },
            (error) => {
              this.error = error.message;
            }
          );
      }
    }
  }

  skip() {
    this.router.navigate(['/dashboard']);
  }

  workOutZone() {}
}
