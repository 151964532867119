import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { Evaluation } from './evaluation.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EvaluationsService {
  error = new Subject<string>();
  skippedToday = false;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private authService: AuthService,
    private router: Router
  ) {
    /*
    don't force them, ask them from the dashboard :)
    // Have they taken today's evaluation?
    this.authService.user.asObservable().subscribe((data) => {
      if (data) {
        this.checkToday().subscribe((evaluationData) => {
          if (evaluationData.length < 1) {
            this.router.navigate(['/evaluations', 'evaluate-today']);
          }
        });
      }
    });
    */
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/evaluations/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const evaluation = new Evaluation(
            responseData.evaluation_id,
            responseData.userId,
            responseData.date_taken,
            responseData.breathlessness_now,
            responseData.breathlessness_24,
            responseData.distress,
            responseData.health,
            responseData.today,
            responseData.since,
            responseData.zone,
            responseData.created,
            responseData.modified
          );
          return evaluation;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchEvaluations(status?: number, date_taken?: string) {
    let searchParams = new HttpParams();
    if (status) {
      searchParams = searchParams.append('status', status.toString());
    }
    if (date_taken) {
      searchParams = searchParams.append('date_taken', date_taken.toString());
    }
    // make them order by date_taken asc
    searchParams = searchParams.append('sort', 'date_taken');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/evaluations', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const EvaluationArray: Evaluation[] = [];
          responseData['_embedded'].evaluations.forEach((evaluation) => {
            EvaluationArray.push(evaluation);
          });
          return EvaluationArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  checkToday() {
    const today = new Date();
    return this.fetchEvaluations(
      null,
      this.datePipe.transform(today, 'yyyy-MM-dd')
    );
  }

  createEvaluation(
    breathlessness_now,
    breathlessness_24,
    distress,
    health,
    today,
    since,
    zone
  ) {
    const todayDate = new Date();
    const evaluationData = {
      breathlessness_now: breathlessness_now,
      breathlessness_24: breathlessness_24,
      distress,
      health,
      today,
      since,
      zone,
      date_taken: this.datePipe.transform(todayDate, 'yyyy-MM-dd'),
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/evaluations',
      evaluationData,
      {
        observe: 'response',
      }
    );
  }

  updateEvaluation(evaluation: Evaluation) {
    const today = new Date();
    const evaluationData = {
      breathlessness_now: evaluation.breathlessness_now,
      breathlessness_24: evaluation.breathlessness_24,
      distress: evaluation.distress,
      health: evaluation.health,
      today: evaluation.today,
      since: evaluation.since,
      zone: evaluation.zone,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/evaluations/' + evaluation.evaluation_id,
      evaluationData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/evaluations/' + id
    );
  }
}
