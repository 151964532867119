import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Evaluation } from '../evaluation.model';
import { EvaluationsService } from '../evaluations.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-evaluations-view',
  templateUrl: './evaluations-view.component.html',
  styleUrls: ['./evaluations-view.component.scss'],
})
export class EvaluationsViewComponent implements OnInit {
  evaluation: any;
  public errors: string[];
  isFetching;
  error;

  constructor(
    private route: ActivatedRoute,
    private evaluationsService: EvaluationsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.evaluationsService.fetch(this.route.snapshot.params['id']).subscribe(
      (evaluation) => {
        this.evaluation = evaluation;
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.evaluationsService.delete(this.evaluation.evaluation_id).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your evaluation has been deleted.', 'success');
            this.router.navigate(['/evaluations', 'all']);
          },
          (error) => {
            this.isFetching = false;
            this.error = error.message;
            Swal.fire(
              'Error',
              'There was an error deleting the evaluation.',
              'error'
            );
          }
        );
      }
    });
  }
}
