
<div class="bg-menu-bg pb-5 d-lg-none d-xl-none d-md-none"></div>

<div class="container mobile-footer-padding content-wrap relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="container mt-n5">
    <img
      *ngIf="resource?.image_filename"
      src="{{ resource.image_filename | getThumbnail: 'wide' }}"
      alt="Placeholder image"
      class="rounded drop-shadow img-fluid fade-in d-none d-sm-block mt-5"
    />

    <img
      *ngIf="resource?.image_filename"
      src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
      alt="Placeholder image"
      class="rounded drop-shadow img-fluid fade-in d-block d-sm-none"
    />

    <ul class="task-list task-list-resources mt-3">
      <li *ngFor="let page of pages">
        <a
          [routerLink]="[
            '/resources',
            page.content_section_id,
            page.content_page_id
          ]"
        >
          <span class="num">
            <span class="icon-wrap">
              <fa-icon
                  *ngIf="!page.icon"
                    [icon]="['far', 'file-alt']"
                    [fixedWidth]="true"
                  ></fa-icon>
                  <fa-icon
                  *ngIf="page.icon"
                    [icon]="['far', page.icon]"
                    [fixedWidth]="true"
                  ></fa-icon>
            </span>
          </span>
          <span class="task">
            <span class="title">{{ page.title }}</span>
            <span class="status">
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
