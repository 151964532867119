import { AuthService } from './../auth/auth.service';
import { forkJoin } from 'rxjs';
import { UserSettingsService } from './../services/user-settings.service';
import { ResourcesService } from './../resources/resources.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  techniques;
  isFetching = false;
  error;
  userSettings;
  showMore = false;
  activeMore = false;
  @Input() section;
  constructor(
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userSettingsService.fetchAll().subscribe((responseData) => {
          this.userSettings = responseData;
        });
      }
    });

    forkJoin({
      contentSection: this.resourcesService.fetchResource(22),
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.techniques = responseData.contentSection;
      this.userSettings = responseData.userSettings;
      this.isFetching = false;
    });
  }
  onToggleMore(): void{
    this.showMore = !this.showMore;
  }
  onHideMore(): void{
    this.showMore = false;
    this.activeMore = false;
  }
  onActiveMore():void{
    this.activeMore = true;
  }
  logout(): void {
    this.authService.logout();
  }
}
