<div [ngClass]="{'bg-dark':routerOutlet.activatedRouteData.bgDark}">
<div class="container-main p-0 relative-md container-xl" (window:resize)="onResize($event)">
  <div class="sidebar-container"
  [ngClass]="{'sidebar-container-on':!routerOutlet.activatedRouteData.hideSiebar, 'sidebar-container-off':routerOutlet.activatedRouteData.hideSiebar }">

      <app-sidebar
        *ngIf="!routerOutlet.activatedRouteData.hideSiebar"
        [section]="routerOutlet.activatedRouteData.pageTitle"
      ></app-sidebar>
    </div>
    <div
      [ngClass]="{'has-sidebar': !routerOutlet.activatedRouteData.hideSiebar}"
    >
      <app-navbar
        [pageTitle]="routerOutlet.activatedRouteData.pageTitle"
        [tallNav]="routerOutlet.activatedRouteData.hideSiebar"
        [home]="routerOutlet.activatedRouteData.home"
        *ngIf="!routerOutlet.activatedRouteData.hideTopNav"
      ></app-navbar>
      <div class="animation-container">
        <div
          [@routeAnimations]="showMobile(routerOutlet)"
          [@routeAnimationsDesktop]="showDesktop(routerOutlet)"
        >
          <router-outlet #routerOutlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
      <app-footer-nav
        *ngIf="!routerOutlet.activatedRouteData.hideFooterNav"
      ></app-footer-nav>
      <app-footer
      *ngIf="!routerOutlet.activatedRouteData.hideFooterNav"
      [hasSidebar]="!routerOutlet.activatedRouteData.hideSiebar"
      ></app-footer>
      <app-add-to-homescreen></app-add-to-homescreen>
  </div>
</div>