<app-subnav [title]="'About Setting Goals'" [section]="'goals'"></app-subnav>
<div class="container mobile-footer-padding content-container text-page-container">
  <div class="row mt-0">
    <div class="col col-xs text-center">
      <span class="icon-wrap mr-1"
      [ngClass]="{'text-primary': step==1}"
        ><fa-icon [icon]="['fas', 'circle']"></fa-icon
      ></span>
      <span class="icon-wrap mr-1"
      [ngClass]="{'text-primary': step==2}"
        ><fa-icon [icon]="['fas', 'circle']"></fa-icon
      ></span>
      <span class="icon-wrap mr-1"
      [ngClass]="{'text-primary': step==3}"
        ><fa-icon [icon]="['fas', 'circle']"></fa-icon
      ></span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <div *ngIf="step == 1">
        <h4 class="content-title">
          1. Have a Target
        </h4>

        <div class="card">
          <div class="card-body">
            <p><strong>Having goals and targets is important.</strong></p>
            <p>
              Goals help you realise what is important to you, remind you what
              you are working towards and record your achievements
            </p>
            <p>Goals and targets can also help direction your actions.</p>
            <p>Spend a moment thinking about what matters to you right now</p>
            <p>Many people want to:</p>
            <ul>
              <li>Stay independent</li>
              <li>Improve your activity levels</li>
              <li>Improve your confident</li>
              <li>Get back to leisure interests and hobbies</li>
              <li>Enjoy time with friends and family</li>
            </ul>
            <p>
              Once you have identified an area that important to you, consider
              what you want to achieve?
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="step == 2">
        <h4 class="content-title">
          2. Make it SMART
        </h4>

        <div class="card">
          <div class="card-body">
            <p>
              Making a goal <strong>SMART</strong> (Specific, Measurable,
              Achievable, Relevant, and Timed) will help you work towards it and
              review your success.
            </p>
            <p>
              <strong><span class="text-primary">S</span>pecific</strong> –
              being clear on what it is you are aiming for
            </p>
            <p>
              <strong><span class="text-primary">M</span>easurable</strong> –
              know you have achieved it
            </p>
            <p>
              <strong><span class="text-primary">A</span>chievable</strong> -
              you should feel that you can achieve your goal. If it feels too
              difficult, considered something on route that is more manageable.
            </p>
            <p>
              <strong><span class="text-primary">R</span>elevant</strong> - your
              goal should also be important to you.
            </p>
            <p>
              <strong><span class="text-primary">T</span>imed</strong> – knowing
              when you want to achieve your goal
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="step == 3">
        <h4 class="content-title">
          3. Explore &amp; Review
        </h4>

        <div class="card">
          <div class="card-body">
            <p>
              Explore SELF-BREATHE to see how different features can support you
              to achieve your goals e.g. exercises to improve fitness
            </p>

            <p>Reviewing your goals:</p>
            <p>
              Goals help you realise what is important to you, remind you what
              you are working towards and record your achievements
            </p>
            <p>Goals and targets can also help direction your actions.</p>
            <p>Spend a moment thinking about what matters to you right now</p>
            <p>Many people want to:</p>
            <ul class="info-list">
              <li>
                <span class="icon-wrap text-primary"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                Stay independent
              </li>
              <li>
                <span class="icon-wrap text-primary"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                Improve your activity levels
              </li>
              <li>
                <span class="icon-wrap text-primary"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                Improve your confident
              </li>
              <li>
                <span class="icon-wrap text-primary"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                Get back to leisure interests and hobbies
              </li>
              <li>
                <span class="icon-wrap text-primary"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                Enjoy time with friends and family
              </li>
            </ul>
            <p>
              Once you have identified an area that important to you, consider
              what you want to achieve?
            </p>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary btn-block mt-2 btn-lg"
        (click)="nextStep()"
      >
        <span *ngIf="step < 3">Next</span>
        <span *ngIf="step == 3">Finish</span>
      </button>
    </div>
  </div>
</div>
