import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subnav-evaluation',
  templateUrl: './subnav-evaluation.component.html',
  styleUrls: ['./subnav-evaluation.component.scss']
})
export class SubnavEvaluationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
