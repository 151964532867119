import { Pipe, PipeTransform } from '@angular/core';
import {Exercise} from './exercise.model';

@Pipe({
  name: 'getTypeReps'
})
export class GetTypeRepsPipe implements PipeTransform {

  transform(repData: {}, type: string, exercises: Exercise[]): unknown {

      let total = 0;
      if(exercises){
        exercises.forEach( (exercise) => {
          if(exercise.exercise_type == type || type === 'total'){
            if(repData[exercise.exercise_id]){
              total += +repData[exercise.exercise_id];
            }
          }
        } );
      }
      return total;


  }

}
