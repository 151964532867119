import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  constructor(private http: HttpClient) {}

  fetchAll(areaId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('area_id', areaId.toString());
    return this.http
      .get<any>(environment.apiUrl + '/resources', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const resourcesArray = [];
          responseData['_embedded'].resources.forEach((resource) => {
            resourcesArray.push(resource);
          });
          return resourcesArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchResource(resourceId) {
    return this.http
      .get<any>(environment.apiUrl + '/resources/' + resourceId, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          responseData.pages.forEach((page, index) => {
            if (responseData.pages[index].content) {
              switch (+responseData.pages[index].page_type_id) {
                // default
                case 1:
                default:
                  responseData.pages[index].content = this.parseContent(
                    responseData.pages[index].content
                  );
                  break;
                // video
                case 2:
                  const contentParts = JSON.parse(responseData.pages[index].content);
                  contentParts.videoUrl = this.parseContent(contentParts.videoUrl);
                  contentParts.posterUrl = this.parseContent(contentParts.posterUrl);
                  contentParts.captionsUrl = this.parseContent(contentParts.captionsUrl);
                  contentParts.content = this.parseContent(contentParts.content);
                  responseData.pages[index].content = contentParts;
                  break;
              }
            }
          });

          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  parseContent(content) {
    return content.split('/assets/').join(environment.staticUrl + '/');
    //    return content.replace('/\/assets\//g', environment.staticUrl + '/');
  }
}

