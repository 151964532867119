<div class="accordion" id="exerciseSubnav">
  <div class="card">
    <div
      class="card-header"
      id="linkOverview"
    >
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/evaluations']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkOverview.isActive"
      >
        Overview
      </button>
    </div>
  </div>
  
  <div class="card">
    <div
      class="card-header"
      id="headingTwo"
    >
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/evaluations', 'all']"
        routerLinkActive="active"
        #linkTwo="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkTwo.isActive"
      >
        All Evaluations
      </button>
    </div>
  </div>
  <div class="card">
    <div
      class="card-header"
      id="headingThree"
    >
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/evaluations', 'evaluate-today']"
        routerLinkActive="active"
        #linkThree="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.collapsed]="!linkThree.isActive"
      >
        Take Today's Evaluation
      </button>
    </div>
  </div>
</div>
