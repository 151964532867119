<div class="container-fluid mobile-footer-padding content-container bg-dark fh login">
  <div class="login-logo">
    <img src="assets/img/logo-white.svg" />
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="text-center"><h2 class="text-white">Sign up to Self-Breathe</h2></div>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
      >
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon
                  [icon]="['fal', 'hashtag']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </div>
            <input
              type="text"
              id="code"
              class="form-control"
              ngModel
              name="code"
              placeholder="Registration Code"
              required
            />
            </div>
          <!--label for="code">registration Code</label-->
        </div>

        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon
                  [icon]="['fal', 'user']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </div>
            <input
              type="text"
              id="firstName"
              class="form-control"
              ngModel
              name="firstName"
              placeholder="First Name"
              required
            />
          </div>
          <!--label for="code">registration Code</label-->
        </div>

        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon
                  [icon]="['fal', 'user']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </div>
            <input
              type="text"
              id="lastName"
              class="form-control"
              ngModel
              name="lastName"
              placeholder="Last Name"
              required
            />
          </div>
          <!--label for="code">registration Code</label-->
        </div>

        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon [icon]="['fal', 'user']" [fixedWidth]="true"></fa-icon
              ></span>
            </div>
            <input
              type="email"
              id="email"
              class="form-control"
              ngModel
              name="email"
              placeholder="Email"
              required
              email
            />
          </div>

          <!--label for="email">Email address</label-->
        </div>
        <div class="form-group">
          <div class="input-group" [ngClass]="(password.dirty && password.invalid) ? 'is-invalid' : ''">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon [icon]="['fal', 'key']" [fixedWidth]="true"></fa-icon
              ></span>
            </div>
            <input
              type="password"
              id="password"
              class="form-control"
              ngModel
              name="password"
              required
              minlength="6"
              placeholder="Password"
              #password="ngModel"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&\#])[A-Za-z\d$@$!%*?&\#]{10,}$"
            />
          </div>
          <p>Minimum ten characters, at least one uppercase letter, one lowercase letter, one number and one special character ($@!%*?&)</p>
          <!--label for="inputPassword">Password</label-->
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><fa-icon [icon]="['fal', 'key']" [fixedWidth]="true"></fa-icon
              ></span>
            </div>
            <input
              type="password"
              id="passwordconfirm"
              class="form-control"
              ngModel
              name="passwordConfirm"
              required
              minlength="6"
              placeholder="Confirm Password"
            />
          </div>
          <!--label for="inputPassword">Password</label-->
        </div>

        <button
          type="submit"
          [disabled]="!authForm.valid || isLoading"
          class="btn btn-primary btn-block btn-lg"
        >
          Sign up
        </button>
      </form>
      <hr />
      <span
        >Already have an account?
        <a [routerLink]="['/login']">Sign in now</a></span
      >
    </div>
  </div>
</div>
