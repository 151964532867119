export class Workout {
  public workout_id?: number;
  public user_id?: number;
  public workout_date: string;
  public bed_reps: number;
  public standing_reps: number;
  public sitting_reps: number;
  public rep_data: string;
  public rep_score: number;
  public created?: string;
  public modified?: string;

  constructor(
    workout_id?: number,
    user_id?: number,
    workout_date?: string,
    bed_reps?: number,
    standing_reps?: number,
    sitting_reps?: number,
    rep_data?: string,
    rep_score?: number,
    created?: string,
    modified?: string
  ) {
    this.workout_id = workout_id;
    this.user_id = user_id;
    this.workout_date = workout_date;
    this.bed_reps = bed_reps;
    this.standing_reps = standing_reps;
    this.sitting_reps = sitting_reps;
    this.rep_data = rep_data;
    this.rep_score = rep_score;
    this.created = created;
    this.modified = modified;
  }
}
